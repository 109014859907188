<template lang="pug">
	.segment-title
		slot
</template>

<script>
export default {
	name: 'SegmentTitle',
	data: () => ({
	}),
	computed: {
	}
};
</script>

<style lang="scss">
	.segment-title {
		font-family: $proxima-bold;
		font-weight: bold;
		line-height: 0.91;
		letter-spacing: 2.2px;
		color: #172937;
		font-size: 11px;
		text-transform: uppercase;
	}
</style>
