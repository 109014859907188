<template lang="pug">
	.project-workbench-pane.project-workbench-pane-add-segment.splitpanes__pane(
		:style="style",
		:class="[tabClass]",
		v-on="$listeners",
		@click="setPaneAsActive"
	)
		draggable.full-height(v-bind="dragAreaOptions")
			empty-segment-state(:projectId="projectId", :paneIdx="paneIdx")

			.project-workbench-pane-header(v-show="dropAreaData")
				.pane-header-item
					.pane-header-title {{ displayTitle }}

</template>

<script>
import SimpleDialogBox from '@/components/SimpleDialogBox.vue';
import ProjectWorkbenchPane from './ProjectWorkbenchPane.vue';
import EmptySegmentState from './EmptySegmentState.vue';

export default {
	extends: ProjectWorkbenchPane,
	name: 'ProjectWorkbenchPaneAddSegment',
	components: {
		SimpleDialogBox,
		'empty-segment-state': EmptySegmentState
	},
	props: {
		projectId: { type: String, required: true },
		paneIdx:   { type: Number }
	},
	data: () => ({

	}),
	computed: {
		isPaneActive() {
			return this.$store.getters['projects/getPaneActiveStatus'](this.projectId, this.paneIdx);
		}
	},
	watch: {
	},
	created() {
	},
	mounted() {
		// override options to disable drag&drop
		this.dragAreaOptions.disabled = true;
	},
	methods: {
		addSegment() {
			const projectId = this.projectId;
			const paneIdx = this.paneIdx;

			const switchToSegmentPane = (segment) => {
				this.$store.dispatch('projects/updateLayoutPane', {
					projectId,
					paneIdx,
					newState: {
						entityType: 'segment',
						attrs: { entityId: segment._id }
					}
				});
			};

			this.$store.dispatch('projects/addSegment', {
				projectId: this.projectId,
				data: {
					name: 'My first section',
					isFolder: false
				}
			}).then(switchToSegmentPane);
		},
		setPaneAsActive() {
			if (this.isPaneActive) {
				return;
			}

			this.$store.dispatch('projects/setPaneActive', {projectId: this.projectId, paneIndex: this.paneIdx});
		},
	}
};
</script>

<style lang="scss">

</style>
