import $router from "@/router";
import $http from "@/lib/axios";
import { wsDisconnect, getWebSocketClient } from "../../lib/wss";

export function loginByEmail({ commit }, { email, password, remember }) {
	const credits = { email, password };

	return $http.post("v1/users/login/email", credits).then((response) => {
		commit("SET_ACCOUNT", response["user"]);
		commit("SET_TOKEN", response["access_token"]);
		commit("SET_APIKEY", response["api_key"] || response["apikey"]);

		// This event is used by Slice Web Clipper extension to detect login
		const event = new CustomEvent("appLogin", { detail: "fromAppToExt" });
		window.dispatchEvent(event);

		return response;
	});
}

export function loginByApiKey({ commit }, apikey) {
	return $http.post("v1/users/login", { apikey }).then((response) => {
		commit("SET_ACCOUNT", response["user"]);
		commit("SET_TOKEN", response["access_token"]);
		commit("SET_APIKEY", response["api_key"] || response["apikey"] || apikey);

		return response;
	});
}

export function loginByOutsetaToken({ commit }, token) {
	commit("SET_TOKEN", token);
	commit("SET_APIKEY", token);
	return $http.get("v1/users/me").then((user) => {
		commit("SET_ACCOUNT", user);
		return user;
	});
}

export function firstTimeSignIn({ dispatch }) {
	// Send event to HubSpot only after user logs in
	const fromLogin = new Boolean(localStorage.getItem("loggedIn")).valueOf();
	if (fromLogin) {
		// dispatch("sendHubSpotEvent", {
		// 	propertyName: "registration_flow___user_sign_in",
		// 	propertyValue: true,
		// });

		// dispatch("sendHubSpotEvent", {
		// 	propertyName: "registration_flow___user_verified",
		// 	propertyValue: true,
		// });

		dispatch("sendMixPanelEvent", {
			eventName: "Users Signs In",
			data: {
				view: "Sign In",
			},
		});
		localStorage.removeItem("loggedIn");
	}
}

// export function sendHubSpotEvent({ commit }, data) {
// 	const socket = getWebSocketClient();
// 	if (socket) {
// 		socket.emit("HUBSPOT_EVENT", data);
// 	}
// }

export function sendMixPanelEvent({ commit }, data) {
	const socket = getWebSocketClient();
	if (socket) {
		socket.emit("MIXPANEL_EVENT", data);
	}
}

export function signup(
	{ commit },
	{ firstName, lastName, organization, phoneNumber, email, password, remember }
) {
	const credits = {
		firstName,
		lastName,
		organization,
		phoneNumber,
		email,
		password,
	};

	return $http.post("v1/users/signup", credits).then((response) => {
		commit("SET_DISPLAY_INTERSTITIAL_PAGE", true);
		commit("SET_ACCOUNT", response["user"]);
		commit("SET_TOKEN", response["access_token"]);
		if (remember) {
			commit("SET_APIKEY", response["api_key"] || response["apikey"]);
		}

		return response;
	});
}

export function forgotPassword(_, { email }) {
	const credits = { email };
	return $http.post("v1/users/password/forgot", credits);
}

export function resetPassword(_, { code, email, password }) {
	const credits = { code, email, password };
	return $http.post("v1/users/password/reset", credits);
}

export function renewToken({ commit, state }) {
	const apikey = state.apikey;

	if (!apikey) {
		return [false, "apikey unavailable"];
	}

	return loginByApiKey({ commit }, apikey);
}

export function refresh({ commit }, params = {}) {
	return $http
		.get(`v1/users/me${params && params.forceCheck ? "?forceCheck=true" : ""}`)
		.then((result) => {
			commit("SET_ACCOUNT", result);
			return result;
		});
}

export function updateUser({ commit }, params = {}) {
	return $http.put("v1/users/me", params).then((result) => {
		commit("SET_ACCOUNT", result);
		return result;
	});
}

export function logout({ commit }) {
	commit("LOGOUT");
	wsDisconnect();
	$router.push({ name: "signup" });
	sessionStorage.clear();
	// This event is used by Slice Web Clipper extension to detect logout
	const event = new CustomEvent("appLogout", { detail: "fromAppToExt" });
	window.dispatchEvent(event);
}

export function sendFeedback(_, data = {}) {
	return $http.post("v1/users/feedback", data);
}

export function updateAccountLimits({ commit }, limits) {
	commit("UPDATE_ACCOUNT_LIMITS", limits);
}

export function setSubscriptionChoice({ commit }, selectedPlan) {
	return $http.post("v1/users/subscriptionChoice", {
		selectedPlan,
	});
}
