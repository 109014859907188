<template lang="pug">
	.project-workbench-pane.project-workbench-pane-resource.splitpanes__pane(
		:style="style",
		:class="[tabClass, { hover: isDragAreaHover }, {active: isPaneActive}]"
		@click="setPaneAsActive"
	)
		.project-workbench-pane-header(v-if="paneData")
			.resource-toolbar
				.diagonal-split-background(:style="{'background-image': resourceColor}")
				.central-content
					.main
						click-to-edit.ellipsis(:value="paneData.name", @valueChanged="updateResourceName")
						//- .title.ellipsis(v-show="!editResourceNameInline", :id="id", :ref="'title'", @click.stop="editTitle",@mouseenter="onMouseEnter", @mouseleave="onMouseLeave") {{ paneData.name }}
						//- input.edit-resource-name(v-show="editResourceNameInline", :ref="'resourceNameInput'", type="text", :value="paneData.name", focus, @keyup.enter="updateResourceName", @keyup.esc="editResourceNameInline = false")
						//- b-tooltip(:disabled.sync="disabledTooltip", :target="`${id}`", offset=50, delay=500) {{ paneData.name }}
				.action-buttons
					.pane-header-close(@click="closePaneInner")

		draggable.max-height.full-width(v-bind="dragAreaOptions")
			.project-workbench-pane-content
				div.info-container(v-show="!showPageLoading && !paneData")
					img.illustration(:src="writerImage", v-bind:class="{mirror: mirror}")
					ladda.btn.btn-app-blue2.no-shadow.sm(
						:loading="false",
						@click="openMenu",
						data-style="zoom-out"
					) Add an idea
					div.action-menu-holder(:class="{ visible:showMenu }")
						.action-menu
							ul
								li
									a(@click="writeContent") Write some content
								li.content-end
									a(@click="addContent") Upload content
								li
									a(@click="writeNotes") Take some notes
								li.notes-end
									a(@click="addResource") Upload notes

					//-.info-box
						<svg xmlns="http://www.w3.org/2000/svg" width="38" height="50" viewBox="0 0 38 50">
								<path fill="#FFF" fill-rule="evenodd" stroke="#4AF" d="M0 1s26.481 5.444 26.481 29.332H37L19.438 50 1.234 30.332H13.09C13.09 17.365 11.942 7.762 0 1h0z"/>
						</svg>
						p After creating them, drag & drop Content or Resource items here
				div.lds-roller(id="lds-roller", v-if="showPageLoading")
					div
					div
					div
					div
					div
					div
					div
				div.error-placeholder(v-if="errorMessage")
					p {{ errorMessage }}

				iframe.full-height.full-width(
					:id="'pdf_' + paneIdx",
					v-if="paneData && isPDF",
					:src="resourceUrl",
					:class="{ disabled: isDraggingDropSortable }",
					data-hj-allow-iframe
				)
				.full-height.full-width.audio-background(v-if="paneData && isAudio")
					audio-payer(:src="resourceUrl", :paneIdx="paneIdx", :projectId="projectId")

</template>

<script>
import _get from 'lodash/get';
import SimpleDropdown from '@/components/SimpleDropdown.vue';
import SimpleDialogBox from '@/components/SimpleDialogBox.vue';
import ProjectWorkbenchPane from './ProjectWorkbenchPane.vue';
import AudioPlayer from './AudioPlayer.vue';
import projectMixin from '../project-mixin';
import { isSliceExtensionInstalled } from '../../utils/util';
import { trackEvent } from '../../lib/helphero';
import randomImageMixin from '../../mixins/random-image';
import ClickToEdit from './ClickToEdit';

export default {
	extends: ProjectWorkbenchPane,
	mixins: [projectMixin, randomImageMixin],
	name: 'ProjectWorkbenchPaneResource',
	components: {
		SimpleDropdown, SimpleDialogBox,
		'audio-payer': AudioPlayer,
		'click-to-edit': ClickToEdit
	},
	props: {
		projectId: { type: String, required: true },
		entityId:  { type: String },
		paneIdx:   { type: Number }
	},
	data: () => ({
		dropAreaGroup: 'g1',
		showPageLoading: false,
		disabledTooltip: false,
		id: null,
		editResourceNameInline: false,
		resourceUrl: '',
		errorMessage: '',
		showMenu: false,
		writerImage: '',
		mirror: false,
		iframeClickMonitor: '',
	}),
	computed: {
		resource() {
			const list = [
				...this.projectResources,
				...this.projectSegments
			];

			return list.find(v => v._id === this.entityId) || null;
		},
		paneData() {
			return this.resource;
		},
		isPDF() {
			return this.resource.type === 'pdf';
		},
		isAudio() {
			return this.resource.type === 'audio';
		},
		displayTitle() {
			return (
				_get(this.dropAreaData, 'name') ||
				_get(this.paneData, 'name')
			);
		},
		resourceColor() {
			const baseColor = this.isPaneActive ? this.getPaneColorByPaneIdx(this.paneIdx) : '#C0C0C0';
			return `-webkit-linear-gradient(135deg, #FFFFFF 50%, ${baseColor} 50%)`;
		},
		isPaneActive() {
			return this.$store.getters['projects/getPaneActiveStatus'](this.projectId, this.paneIdx);
		},
		activePaneIndex() {
			return this.$store.getters['projects/getActivePaneIndex'](this.projectId);
		},
	},
	watch: {
		entityId: {
			immediate: true,
			handler(newValue, oldValue) {
				this.refresh({entityId: newValue});
			}
		},
	},
	async	mounted() {
		// override options to disable drag&drop
		this.dragAreaOptions.disabled = true;

		this.id = this._uid;
		this.sliceExtensionInstalled = await isSliceExtensionInstalled();
		if (this.sliceExtensionInstalled) {
			trackEvent('extension_installed');
		}

		this.$root.$on('refresh-resource-pane', this.refresh);
	},
	methods: {
		closePaneInner() {
			switch(this.resource.group) {
				case 'content': {
					this.$mixPanel.trackEvent('Content Removed from Pane', 'Project', 'Project Pane');
					break;
				}
				case 'resource': {
					this.$mixPanel.trackEvent('Note Removed from Pane', 'Project', 'Project Pane');
					break;
				}
			}
			this.closePane();
		},

		async refresh(data) {
			if (data.hasOwnProperty('paneIndex') && !isNaN(data.paneIndex) && data.paneIndex !== this.paneIdx) {
				return;
			}
			this.errorMessage = '';
			const entityId = data.entityId;
			if (!entityId) {
				return;
			}

			this.showPageLoading = true;
			this.openFile(entityId);
		},
		loadItemToActivePane(item, itemEntityType) {
			const newState = {
				active: true,
				entityType: itemEntityType,
				attrs: {entityId: item._id}
			};
			this.$store.dispatch('projects/updateLayoutPane', {
				projectId: this.projectId,
				paneIdx: this.activePaneIndex,
				newState: newState
			});
		},
		openMenu(event) {
			this.showMenu = !this.showMenu;
		},
		writeContent() {
			const projectId = this.projectId;
			const data = {projectId, fetch: false, data: {name: 'Untitled item', isFolder: false, type: 'prosemirror', group: 'content'}};
			this.$store.dispatch('projects/addSegment', data).then(
				segment => this.loadItemToActivePane(segment, 'segment')
			);
			this.showMenu = false;
		},
		addContent() {
			this.$root.$emit('app:modal:project_resource_new', { projectId: this.projectId, data: { group: 'content' }});
			this.showMenu = false;
		},
		writeNotes() {
			const projectId = this.projectId;
			const data = {projectId, fetch: false, data: {name: 'Untitled item', isFolder: false, type: 'prosemirror', group: 'resource'}};
			this.$store.dispatch('projects/addResource', data).then(
				resource => this.loadItemToActivePane(resource, 'segment')
			);
			this.showMenu = false;
		},
		addResource() {
			this.$mixPanel.trackEvent('Begin Upload Notes', 'Project ', 'Project Pane');
			this.$root.$emit('app:modal:project_resource_new', { projectId: this.projectId, data: { group: 'resource' }});
			this.showMenu = false;
		},
		getItemContent(resourceId) {
			const params = { projectId: this.projectId, resourceId: resourceId };
			return this.$store.dispatch('projects/getResourceContent', params).catch(err => {
				this.displayError = err.message;
			});
		},
		async openFile(entityId) {
			const itemContent = await this.getItemContent(entityId);
			if (itemContent.type === 'prosemirror') {
				this.loadItemToActivePane(itemContent, 'segment');
				return;
			}

			if (!itemContent || !itemContent.success) {
				this.resourceUrl = '';
				this.showPageLoading = false;
				this.errorMessage = 'Resource no longer available';
				return;
			}

			this.openUrl(itemContent.url);
		},
		async openUrl(url) {
			if (url === null) {
				return;
			}

			if (this.resource) {
				this.$root.$emit('segment-item-loaded', {
					id: this.resource._id,
					paneIdx: this.paneIdx
				});
			}

			this.resourceUrl = url.trim();
			this.showPageLoading = false;
		},
		onMouseLeave() {
			this.disabledTooltip = false;
		},
		onMouseEnter() {
			const element = this.$refs.title;
			this.disabledTooltip = !(element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth);
		},
		updateResourceName(newName) {
			const data = {
				name: newName,
				type: this.resource.type,
				group: this.resource.group
			};
			this.$mixPanel.trackEvent('Rename Content', 'Project', 'Pane');

			this.$store.dispatch('projects/updateResource', {
				projectId: this.projectId,
				resourceId: this.entityId,
				data
			}).then(() => {
				this.editResourceNameInline = false;
			});
		},
		editTitle() {
			if (!this.isPaneActive) {
				this.setPaneAsActive();
				return;
			}
			this.editResourceName();
		},
		editResourceName() {
			this.editResourceNameInline = !this.editResourceNameInline;
			if (this.editResourceNameInline) {
				document.addEventListener('click', this.editResourceName);
			} else {
				document.removeEventListener('click', this.editResourceName);
			}

			this.$nextTick(() => {
				if (this.$refs.resourceNameInput) {
					this.$refs.resourceNameInput.focus();
				}
			});
		},
		setPaneAsActive() {
			if (this.isPaneActive) {
				return;
			}

			this.$store.dispatch('projects/setPaneActive', {projectId: this.projectId, paneIndex: this.paneIdx})
				.then(() => {
						this.$root.$emit('segment-item-loaded', {id: this.entityId, paneIdx: this.paneIdx});
						this.$root.$emit('close-inline-edit', this.paneIdx);
					}
				);
		},
		closePopupOnOutsideClick(event) {
			if (event.target.id !== 'ladda-menu-button' && event.target.id !=='ladda-button') {
				this.showMenu = false;
			}
		},
		setWriterImage() {
			this.writerImage = this.getRandomWriterImage();
			this.mirror = this.applyMirrorEffect();
		},
		monitorPDFIframeClick() {
			this.iframeClickMonitor = setInterval(() => {
				if (document?.activeElement?.id === `pdf_${this.paneIdx}`) {
					if (this.editResourceNameInline) {
						this.editResourceNameInline = false;
					}
					if (!this.isPaneActive) {
						this.setPaneAsActive();
						clearInterval(this.iframeClickMonitor);
						this.monitorPDFIframeClick();
					}
				}
			}, 100);
		}
	},
	created() {
		document.addEventListener('click', e => this.closePopupOnOutsideClick(e));
		this.setWriterImage();
		this.monitorPDFIframeClick();
		this.$root.$on('close-inline-edit', paneIndex => {
			if (this.paneIdx !== paneIndex) {
				setTimeout(() => this.editResourceNameInline = false, 0);
			}
		});
	},
	beforeDestroy() {
		document.removeEventListener('click', e => this.closePopupOnOutsideClick(e));
		clearInterval(this.iframeClickMonitor);
	},
};
</script>

<style lang="scss">
	.project-workbench-pane-resource {
		&.active {
			.title {
				cursor: pointer;
			}
		}
		.bookmark-btn {
			margin-right: 20px;
		}

		.audio-background {
			background-image: url('../../assets/panel/pattern-stripes-45.png');
		}

		.no-resource-toolbar {
			display: grid;
			width: 100%;
			height: 100%;
			grid-template-columns: auto 100px;

			input {
				border: none;
				padding-left: 30px;
				font-family: $proxima-medium;
				font-size: 15px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: -0.01px;
				color: $dark-grey-blue;
			}

			.action-buttons {
				display: flex;
				justify-self: center;
				align-self: center;

				.pane-header-close {
					align-self: center;
					margin-right: 20px;
				}
			}
		}

		.info-box {
			width: 191px;
			height: 106px;
			border-radius: 4px;
			border: dashed 1px #dad4df;
			background-color: #ffffff;
			margin: 0 auto;

			&:hover {
				border: dashed 1px #3e98f3;
				background-color: #f9f9f9;

				svg path {
					fill: #3e98f3;
				}
			}

			svg {
				margin-top: -36px;
			}

			p {
				font-family: $proxima-medium;
				font-size: 13px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.15;
				letter-spacing: -0.04px;
				text-align: center;
				color: $dark-grey-blue;
				margin: 10px auto 0;
				width: 125px;
			}
		}

		.resource-toolbar {
			display: grid;
			width: 100%;
			height: 100%;
			grid-template-columns: 25px auto 5%;

			.diagonal-split-background{
				width: 25px;
				height: 25px;
			}

			.central-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				.main {
					.pencil-icon-position {
						margin-left: 10px;
					}

					.edit-resource-name {
						grid-row-start: 2;
						width: 95%;
						color: #353B54;
						font-family: $proxima-semi;
						font-size: 16px;
						letter-spacing: 0.4px;
						font-weight: 500;
						border: none;
						border-bottom: 1px solid #d8d9de;
						margin: 2px 0 0 10px;
						padding: 0;
					}

					.navigation-buttons {
						padding-top: 4px;
						min-width: 60px;

						.vertical-bar {
							padding: 0 10px;
							color: #dedae7;
						}

						img {
							cursor: pointer;
						}

						img[disabled] {
							opacity: 0.4;
						}
					}

					.title {
						font-family: $proxima-semi;
						font-size: 16px;
						font-weight: 600;
						font-stretch: normal;
						font-style: normal;
						line-height: normal;
						letter-spacing: 0.4px;
						color: #353B54;
						padding-left: 10px;
						display: flex;
					}

					.ellipsis {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						width: 85%;
					}
				}
			}

			.action-buttons {
				display: flex;
				justify-content: flex-end;
				.pane-header-close {
					align-self: center;
					margin-right: 20px;
				}
			}
		}

		.can-not-open {
			margin: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			font-size: medium;
			text-align: center;

			a {
				cursor: pointer;
				font-size: initial;
			}
		}

		iframe {
			padding: 0;
			margin: 0;
			border: none;
		}

		&.hover {
			opacity: 0.7;
			pointer-events: none;
		}

		.button-back, .button-forward, .button-refresh, .button-save-resource {
			padding: 0;
			margin: 0;
			cursor: pointer;
			background-color: transparent;
			border: none;

			img {
				width: 12px;
				height: 12px;
			}

			&.disabled, &:disabled {
				opacity: 0.7;
				cursor: initial;
			}

			&:hover, &:focus, &:active {
				background-color: rgba(255, 255, 255, 0.8);
				border-radius: 50%;
			}
		}

		.button-save-resource img {
			width: initial;
			height: initial;
			opacity: 0.6;
		}

		.button-forward img {
			transform: rotate(180deg);
		}

		.address-line {
			width: 100%;

			input {
				width: 100%;
				border-radius: 7px;
				background-color: #E3E8F3;
				border: 0;
				padding: 2px 15px;
				margin: 0;
				color: #3B3B3B;
				font-size: 18px;
			}
		}

		.resource-view.disabled, .search-view.disabled {
			pointer-events: none;
		}

		.link {
			cursor: pointer;
		}
		.project-workbench-pane-content {
			display: flex;
			justify-content: center;
			align-items: center;
			.info-container {
				position: relative;
				display: flex;
				flex-direction: column;

				img {
					width: 116px;
					height: 56px;
					margin-left: 17px;
					&.mirror {
						transform: scaleX(-1);
						margin-left: 17px;
					}
				}
				.action-menu-holder {
					width: 180px;
					border-radius: 4px;
					box-shadow: 0 20px 30px -5px rgba(0, 0, 0, 0.15);
					background-color: #ffffff;
					z-index: 1500;
					padding-top: 8px;
					position: absolute;
					bottom: -150px;
					left: 50%;
					display: none;
					.action-menu {
						max-width: fit-content;
						ul {
							padding-left: 30px;
							text-align: left;
							list-style: none;
							li {
								padding-top: 10px;
								&.content-end {
									border-bottom: 1px solid #EFF2FC;
									padding-bottom: 10px;
								}
								a {
									cursor: pointer;
									font-size: 15px;
									font-weight: 500;
									color: #000;
									font-stretch: normal;
									font-style: normal;
									line-height: normal;
									letter-spacing: -0.2px;
									font-family: 'Proxima Nova Medium', sans-serif;
									&:hover {
										color: #3e98f3;
									}
								}
							}
						}
					}
				}
				.visible {
					display: block;
				}
			}
		}
	}

	.pointer {
		cursor: pointer;
	}

	.error-placeholder {
		display: inline-block;
		position: relative;
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		font-size: medium;
		text-align: center;
	}

	.lds-roller {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		font-size: medium;
		text-align: center;
	}
	.lds-roller div {
		animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		transform-origin: 40px 40px;
	}
	.lds-roller div:after {
		content: " ";
		display: block;
		position: absolute;
		width: 7px;
		height: 7px;
		border-radius: 50%;
		background: lightskyblue;
		margin: -4px 0 0 -4px;
	}
	.lds-roller div:nth-child(1) {
		animation-delay: -0.036s;
	}
	.lds-roller div:nth-child(1):after {
		top: 63px;
		left: 63px;
	}
	.lds-roller div:nth-child(2) {
		animation-delay: -0.072s;
	}
	.lds-roller div:nth-child(2):after {
		top: 68px;
		left: 56px;
	}
	.lds-roller div:nth-child(3) {
		animation-delay: -0.108s;
	}
	.lds-roller div:nth-child(3):after {
		top: 71px;
		left: 48px;
	}
	.lds-roller div:nth-child(4) {
		animation-delay: -0.144s;
	}
	.lds-roller div:nth-child(4):after {
		top: 72px;
		left: 40px;
	}
	.lds-roller div:nth-child(5) {
		animation-delay: -0.18s;
	}
	.lds-roller div:nth-child(5):after {
		top: 71px;
		left: 32px;
	}
	.lds-roller div:nth-child(6) {
		animation-delay: -0.216s;
	}
	.lds-roller div:nth-child(6):after {
		top: 68px;
		left: 24px;
	}
	.lds-roller div:nth-child(7) {
		animation-delay: -0.252s;
	}
	.lds-roller div:nth-child(7):after {
		top: 63px;
		left: 17px;
	}
	.lds-roller div:nth-child(8) {
		animation-delay: -0.288s;
	}
	.lds-roller div:nth-child(8):after {
		top: 56px;
		left: 12px;
	}
	@keyframes lds-roller {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
