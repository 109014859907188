<template lang="pug">
	b-modal#feedback_modal(size="md", centered, :no-close-on-backdrop="true", title="Thanks for the feedback!", @shown="onShown")
		div For <span class="strong-text">this particular issue</span>, please choose the option that you think would be best.
		ul
			li Type your feedback in the box below.
				.description-text Usually best for something short and sweet.
			li
				a(href='https://calendly.com/joe-at-slice/meeting', target="_blank") Schedule a web meeting
				.description-text Usually best when screen-sharing will help illustrate a concept.
			li Call Joe at
				a(href='tel:260-740-8501')  260-740-8501
				.description-text Usually best when you have a strong opinion about something.
			li Email Joe at
				a(:href="feedbackContent", @click="openSeparateWindow")  joe@slice.tech
				.description-text Usually best when extended feedback will benefit from structure.
		p Thanks again!

		textarea.form-control.form-control-lg(
			@focus="onFeedbackTextFocused(true)",
			@blur="onFeedbackTextFocused(false)",
			:ref="'feedbackTextArea'",
			v-model="feedbackText",
			placeholder="Enter your feedback...",
			name="feedback")
		br

		template(slot="modal-footer")
			.action-buttons
				.btn.btn-link.sm.absolute-position(@click="close(true)") Cancel
				ladda.btn.btn-app-blue2.sm(
					:loading="isFeedbackSending",
					data-style="zoom-out",
					:disabled="feedbackText === ''",
					@click="sendFeedback"
				) Send
</template>

<script>
import _get from "lodash/get";
import { mapGetters } from "vuex";

export default {
	name: "FeedbackModal",
	components: {},
	props: {},
	data: () => ({
		isFeedbackSending: false,
		feedbackText: "",
		userDetails: {},
	}),
	computed: {
		feedbackContent() {
			const email = "joe@slice.tech";
			const subject = `[FEEDBACK] From ${this.userDetails.name}`;
			const url = window.location.origin + "/" + this.$route.fullPath;
			const body = `
			Please leave this information for the Slice team. Thanks!
			%0D%0A
			%0D%0A
			* Your user ID: ${this.userDetails.id}
			%0D%0A
			%0D%0A
			* Your email address: ${this.userDetails.email}
			%0D%0A
			%0D%0A
			* Your URL: ${url}
			%0D%0A
			%0D%0A
			%0D%0A
			Please share your feedback with us below. Thanks!
			%0D%0A
			%0D%0A
			%0D%0A
			`;

			return `mailto:${email}?subject=${subject}&body=${body}`;
		},
		...mapGetters({
			account: "user/account",
		}),
	},
	watch: {
		account: {
			immediate: true,
			handler(newValue) {
				const email = _get(newValue, "email");
				const userId = _get(newValue, "id");
				const name = _get(newValue, "fullName");
				if (email && userId) {
					this.userDetails = {
						email: email,
						id: userId,
						name: name,
					};
				}
			},
		},
	},
	created() {},
	methods: {
		onFeedbackTextFocused(fieldSelected) {
			const eventName = fieldSelected
				? "Select Feedback Field"
				: "Deselect Feedback Field";
			this.$mixPanel.trackEvent(eventName, "Project", "Feedback");
		},
		async sendFeedback() {
			this.$mixPanel.trackEvent("Send Feedback", "Project", "Feedback");
			const data = {
				feedbackText: this.feedbackText,
				userInfo: this.userDetails,
				url: window.location.origin + "/" + this.$route.fullPath,
			};

			this.isFeedbackSending = true;
			await this.$store.dispatch("user/sendFeedback", data);
			this.isFeedbackSending = false;
			this.feedbackText = "";
			this.close();
		},
		openSeparateWindow(event) {
			window.open(`${this.feedbackContent}`, "Mail");
			event.preventDefault();
			return false;
		},
		close(fromButton = false) {
			if (fromButton) {
				this.$mixPanel.trackEvent("Cancel Feedback", "Project", "Feedback");
			}
			this.$root.$emit("bv::hide::modal", "feedback_modal");
		},
		onShown() {
			this.$refs.feedbackTextArea.focus();
		},
	},
};
</script>

<style lang="scss">
#feedback_modal___BV_modal_content_ {
	.modal-title {
		font-weight: bolder;
	}

	.modal-body {
		padding-top: 0px;
		color: #353b54;
		font-size: 14px;
		line-height: 20px;
	}

	.strong-text {
		font-weight: bold;
	}

	.description-text {
		font-style: italic;
		margin-left: 20px;
	}

	ul li {
		list-style-type: disc;
	}
}
</style>
