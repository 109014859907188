<template lang="pug">
	.simple-dropdown
		b-dropdown(v-bind="$attrs", v-on="$listeners", toggle-tag="a", variant="link", toggle-class="text-decoration-none" no-caret)
			template(v-slot:button-content)
				.simple-dropdown-container
					slot(name="content")
			slot
</template>

<script>
export default {
	name: 'SimpleDropdown',
	components: {},
	computed: {
	},
	watch: {
	},
	created() {
	},
	methods: {
	}
};
</script>

<style lang="scss">
	.simple-dropdown {
		.simple-dropdown-container {
			padding: 6px;
			margin: -6px;
		}

		.dropdown-menu {
			border: none;
			border-radius: 3px;
			background-color: #FFFFFF;
			box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
		}

		.dropdown-toggle img {
			display: block;
		}

		.dropdown-item {
			font-size: 12px;
			font-weight: 300;
			letter-spacing: 0.75px;
			line-height: 24px;

			&.active, &:active {
				color: #353B54;
				background-color: $gray-200;
			}

			img {
				display: inline-block;
				height: 14px;
				margin-right: 15px;
			}
		}
	}
</style>
