export const container = 'panel';

export const routes = [
	{
		path: '/projects',
		name: 'projects',
		component: () => import(/* webpackChunkName: "projects" */ './ProjectsPage.vue'),
		meta: {
			title: 'Projects',
			bodyClass: 'simple',
			containerClass: 'container'
		}
	},
	{
		path: '/projects/:id/workbench',
		name: 'project.workbench',
		component: () => import(/* webpackChunkName: "project-workbench" */ './ProjectWorkbenchPage.vue'),
		meta: {
			title: 'Project Workbench',
			appHeaderTitle: 'Project Workbench',
			containerClass: null,
			sidebarLeftComponent: () => import(/* webpackChunkName: "project-workbench" */ './Workbench/ProjectWorkbenchSidebar.vue'),
		}
	},
// {
// 	path: '/projects/:id/preview-document',
// 	name: 'project.preview-document',
// 	component: () => import(/* webpackChunkName: "project-preview-document" */ './ProjectsPreviewDocumentPage.vue'),
// 	meta: {
// 		title: 'Preview Document',
// 		bodyClass: 'simple hide-header',
// 		containerClass: 'container-fluid mb-0 mt-0 p-0'
// 	}
//}
	{
		component: () => import(/* webpackChunkName: "auth" */ './Output.vue'),
		path: '/output/:projectId/:outputId',
		name: 'output',
		meta: {
			title: 'Output',
			bodyClass: 'hide'
		}
	}
];
