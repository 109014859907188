import _debounce from "lodash/debounce";
import _has from "lodash/has";
import router from "../router";

const PAGE_LOADER_DEBOUNCE = _debounce(function (commit, value) {
	commit("SET_PAGE_LOADER_STATE", value);
}, 300);

export function pageLoader({ commit, state }, value) {
	if (value && state.pageLoader) return;

	if (value) {
		commit("SET_PAGE_LOADER_STATE", true);
	} else {
		PAGE_LOADER_DEBOUNCE(commit, false);
	}
}

export function touch({ commit, state }, [path, defVaule]) {
	if (!_has(state, path)) {
		commit("SET", [path, defVaule]);
	}
}

export function setWebSocketState({ commit }, value) {
	commit("SET_WS_CONNECTION_STATE", value);
}

export function webSocketMessageReceived({ commit, dispatch }, message) {
	switch (message.command) {
		case "REFRESH_CONTENT_ITEMS": {
			const projectId = message.data.projectId;
			dispatch("projects/fetchSegments", { projectId });
			break;
		}
		case "REFRESH_RESOURCE_ITEMS": {
			const projectId = message.data.projectId;
			dispatch("projects/fetchResources", { projectId });
			break;
		}
		case "REFRESH_PROJECT_LIST": {
			dispatch("projects/fetchProjects");
			break;
		}
		case "PROJECT_UPDATED": {
			dispatch("projects/projectUpdated", message.data);
			break;
		}
		case "CLIENT_APP_VERSION_CHECK": {
			if (message.data.reload) {
				commit("SET_LOADING_OVERLAY_STATE", {
					active: true,
					message:
						"For the best experience, please upgrade Slice by refreshing your browser.",
				});
			} else {
				commit("RESET_LOADING_OVERLAY_STATE");
			}
			break;
		}
		case "REMOVED_FROM_PROJECT": {
			const url = window.location.href;
			const projectId = message.data.projectId;
			if (
				url.length &&
				projectId.length &&
				url.indexOf(`projects/${projectId}/workbench`) > -1
			) {
				commit("projects/REMOVED_FROM_PROJECT", true);
				commit(
					"projects/REMOVED_FROM_PROJECT_MESSAGE",
					"You have been removed from the project by one of the owners."
				);
				router.push("/projects");
			} else if (
				url.length &&
				projectId.length &&
				window.location.pathname === "/projects"
			) {
				commit("projects/REMOVED_FROM_PROJECT", true);
				commit(
					"projects/REMOVED_FROM_PROJECT_MESSAGE",
					"You have been removed from the project by one of the owners."
				);
				dispatch("projects/fetchProjects");
			} else {
				// Fetch projects if user is observing projects page
				dispatch("projects/fetchProjects");
			}
			break;
		}
		default: {
			console.log("Unknown command received", message);
		}
	}
}

export function appOffline({ commit }) {
	commit("SET_LOADING_OVERLAY_STATE", {
		active: true,
		message: "Trying to reconnect...",
	});
}

export function appOnline({ commit }) {
	commit("RESET_LOADING_OVERLAY_STATE");
}

export function showLoader({ commit }, message) {
	commit("SET_LOADING_OVERLAY_STATE", {
		active: true,
		message: message,
	});
}

export function stopLoader({ commit }) {
	commit("SET_LOADING_OVERLAY_STATE", {
		active: false,
	});
}
