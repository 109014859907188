export function projects(state) {
	return state.displayList;
}

export function updatedEntity(state) {
	return state.updatedEntity;
}

export function getRemovedFromProject(state) {
	return state.removedFromProject;
}

export function getRemovedFromProjectMessage(state) {
	return state.removedFromProjectMessage;
}

export function projectUpdated(state) {
	return state.projectUpdated;
}
