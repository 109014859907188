<template lang="pug">
	b-modal#prose_mirror_add_image_modal(centered, size="md", :no-close-on-backdrop="true", title="Add an image", @shown="onShown")
		transition(name="slide-fade")
		div
			p.subheading Slice supports PNG, JPG, and GIF files.
			.form-group
				label If you'd like to link to an image, enter its address here.
				input.form-control.form-control-lg.padding-right(
					@keyup.enter="submit",
					placeholder="https://",
					name="url",
					ref="url",
					v-model="model.url")
				br
				.drop-resource(@click="onDropResourceClick", @drop.prevent="fileDropped", v-cloak, @dragover.prevent, v-bind:class="{ error: showFileTypeError}")
					<svg xmlns="http://www.w3.org/2000/svg" width="38" height="50" viewBox="0 0 38 50">
							<path fill="#FFF" fill-rule="evenodd" stroke="#4AF" d="M0 1s26.481 5.444 26.481 29.332H37L19.438 50 1.234 30.332H13.09C13.09 17.365 11.942 7.762 0 1h0z"/>
					</svg>

					.info-box-container(v-if="showFileTypeError")
						.info-box Provided file is not an image
					.info-box-container(v-else-if="model.fileName")
						.info-box {{model.fileName}}
					.info-box-container(v-else)
						.info-box If you'd like to upload an image,
						.info-box drag-and-drop it here,
						.info-box or click to access the file system.
					input.input-file(ref="file", type="file", accept="image/x-png,image/png,image/gif,image/jpeg", @change="onSelect")

		template(slot="modal-footer")
			img(src="~@/assets/illustrations/books.svg")
			.action-buttons
				.btn.btn-link.sm.absolute-position(@click="close") Cancel
				ladda.btn.btn-app-blue2.sm(
					:loading="isSubmit",
					@click="submit",
					data-style="zoom-out",
					:disabled="isSubmitButtonDisabled"
				) Add image

</template>

<script>

import { isUrlValid } from '@/utils/util';

export default {
	name: 'AddImageModal',
	data: () => ({
		imgSrc: null,
		isSubmit: false,
		onImageUploaded: null,
		model: {
			url: null,
			file: null,
			fileName: null,
			type: null
		},
		showFileTypeError: false
	}),
	computed: {
		isSubmitButtonDisabled() {
			if (this.model.url) {
				return !isUrlValid(this.model.url);
			}

			return !this.model.file || this.showFileTypeError;
		}
	},
	mounted() {
		this._openHandler = ({ projectId, onImageUploaded, data = {} }) => {
			this.show();
			this.onImageUploaded = onImageUploaded;
		};

		this.$root.$on('app:modal:prose_mirror_add_image_modal', this._openHandler);
	},
	watch: {
		'model.url': {
			handler: 'onUrlChanged',
		}
	},
	beforeDestroy() {
		this.$root.$off('app:modal:prose_mirror_add_image_modal', this._openHandler);
	},
	methods: {
		onUrlChanged(newVal) {
			// Reset selected file if url is changed
			if (newVal) {
				this.resetFile();
			}
		},
		resetFile() {
			this.model.file = null;
			this.model.fileName = null;
			this.model.type = null;
		},
		onDropResourceClick(e) {
			this.$refs.file.click();
		},
		onSelect(e) {
			const file = this.$refs.file.files[0];
			if (!file || !this.isFileSupported(file.type)) {
				this.showFileTypeError = true;
				return;
			}

			this.model = {
				file,
				fileName: file.name,
				type: file.type
			};
			this.showFileTypeError = false;
		},
		fileDropped(e) {
			let droppedFiles = e.dataTransfer.files;
			if(!droppedFiles || droppedFiles.length > 1) {
				return;
			}

			const file = droppedFiles[0];
			if (!file || !this.isFileSupported(file.type)) {
				this.showFileTypeError = true;
				return;
			}

			this.model = {
				file,
				fileName: file.name,
				type: file.type
			};
			this.showFileTypeError = false;
		},
		onShown() {
			this.isSubmit = false;

			if (this.$refs.form) {
				this.$refs.form.$validator.reset();
				this.$refs.form.errors.clear();
			}
		},
		async submit(e) {
			e.preventDefault();
			e.stopPropagation();

			// User entered image url manually
			if (this.model.url) {
				this.onImageUploaded(this.model.url);
				this.close();
				return;
			}

			this.isSubmit = true;
			return this.$store.dispatch('projects/uploadImage', {
				data: this.model
			}).then((data) => {
				this.isSubmit = false;
				this.onImageUploaded(data.url);
				this.close();
			}).catch(err => {
				this.isSubmit = false;
			});
		},
		show() {
			this.$root.$emit('bv::show::modal', 'prose_mirror_add_image_modal');
		},
		close() {
			this.$root.$emit('bv::hide::modal', 'prose_mirror_add_image_modal');
			this.model = {
				file: null,
				fileName: null,
				type: null
			};
		},
		isFileSupported(fileType) {
			return ['image/x-png', 'image/png', 'image/gif', 'image/jpeg'].includes(fileType);
		},
	}
};
</script>

<style lang="scss" scoped>
	.subheading {
		margin-top: -50px;
		margin-bottom: 3rem;
		color: black;
	}

	.drop-resource {
		margin-top: 25px !important;
		height: 100px!important;
	}

	.input-file {
		opacity: 0;
		cursor: pointer;
	}

	.file-name {
		width: 100% !important;
		height: 42px;
		overflow: auto;
	}

	.modal-content .modal-body .drop-resource {
		height: 100%;
		&.error {
			border-color: red !important;
		}
	}
</style>
