  <template lang="pug">
    div
      add-image-modal
      add-link-modal
      menu-item(v-for="(item, index) in items" :key="index" v-bind="item")
  </template>

<script>
import MenuItem from './MenuItem.vue';
import AddImageModal from './AddImageModal';
import AddLinkModal from './AddLinkModal';

export default {
  components: {
    MenuItem,
    AddImageModal,
    AddLinkModal
  },

  props: {
    projectId: {
      type: String,
      required: true
    },
    editor: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      items: [
        {
          icon: 'bold',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
        },
        {
          icon: 'italic',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
        },
        {
          icon: 'strike',
          title: 'Strike',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike'),
        },
        {
          icon: 'underline',
          title: 'Underline',
          action: () => this.editor.chain().focus().toggleUnderline().run(),
          isActive: () => this.editor.isActive('underline'),
        },
        {
          icon: 'code',
          title: 'Code Block',
          action: () => this.editor.chain().focus().toggleCodeBlock().run(),
          isActive: () => this.editor.isActive('codeBlock'),
        },
        {
          icon: 'paragraph',
          title: 'Paragraph',
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive('paragraph'),
        },
        {
          icon: 'link',
          title: 'Link',
          action: () => {
            const isLinkSet = this.editor.isActive('link');
            if (isLinkSet) {
              this.editor.chain().focus().unsetLink().run();
            } else {
              this.showLinkDialog().then(url => {
                this.editor.chain().focus().toggleLink({ href: url }).run();
              });
            }
          },
          isActive: () => this.editor.isActive('link'),
        },
        {
          icon: 'h1',
          title: 'Heading 1',
          action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 1 }),
        },
        {
          icon: 'h2',
          title: 'Heading 2',
          action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 2 }),
        },
        {
          icon: 'h3',
          title: 'Heading 3',
          action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 3 }),
        },
        {
          icon: 'ul',
          title: 'Bullet List',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: 'ol',
          title: 'Ordered List',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList'),
        },
        {
          icon: 'quote',
          title: 'Blockquote',
          action: () => this.editor.chain().focus().toggleBlockquote().run(),
          isActive: () => this.editor.isActive('blockquote'),
        },
        {
          icon: 'hr',
          title: 'Horizontal Rule',
          action: () => this.editor.chain().focus().setHorizontalRule().run(),
        },
        {
          icon: 'image',
          title: 'Image',
          action: () => {
            this.showUploadDialog().then(src => {
              this.editor.chain().focus().setImage({ src: src }).run();
            });
          }
        },
        {
          icon: 'undo',
          title: 'Undo',
          action: () => this.editor.chain().focus().undo().run(),
        },
        {
          icon: 'redo',
          title: 'Redo',
          action: () => this.editor.chain().focus().redo().run(),
        },
        {
          icon: 'spellcheck1',
          title: 'Toggle Spellcheck',
          action: (e) => {
            const isSpellCheckOn = this.editor.options.editorProps.attributes.spellcheck === true;
            // eslint-disable-next-line vue/no-mutating-props
            this.editor.options.editorProps.attributes.spellcheck = !isSpellCheckOn;
            this.editor.commands.focus();
          },
          isActive: () => {
            return this.editor.options.editorProps.attributes.spellcheck;
          }
        }
      ],
    };
  },
  methods: {
    showUploadDialog() {
      return new Promise((resolve, reject) => {
        this.$root.$emit('app:modal:prose_mirror_add_image_modal', {
          projectId: this.projectId,
          onImageUploaded: (imgSrc) => {
            resolve(imgSrc);
          }
        });
      });
    },
    showLinkDialog() {
      return new Promise((resolve, reject) => {
        this.$root.$emit('app:modal:prose_mirror_add_link_modal', {
          onUrlAdded: (url) => {
            resolve(url);
          }
        });
      });
    }
  }
};
</script>
