import store from '@/store';
import { io } from "socket.io-client";

let socket;

export function getWebSocketClient() {
	return socket;
}

export async function wsConnect() {
	const user = store.state.user;
	if (!user) {
		throw new Error('User not authenticated');
	}

	const userNamespace = `${user.account.id}`;

	socket = io(process.env.VUE_APP_WS_ENDPOINT, {
		extraHeaders: {
			Authorization: `Bearer ${user.token}`
		}
	});

	socket.on('disconnect', function() {
		store.dispatch('setWebSocketState', 'disconnected');
	});

	socket.on('connect', (e) => {
		store.dispatch('setWebSocketState', 'connected');

		socket.emit('CLIENT_APP_VERSION_CHECK', {
			currentVersion: process.env.VUE_APP_CURRENT_VERSION
		});
	});

	socket.on('CLIENT_APP_VERSION_CHECK', (data) => {
		store.dispatch('webSocketMessageReceived', {
			command: 'CLIENT_APP_VERSION_CHECK',
			data
		});
	});

	// Handle all events related to the user updates
	socket.on(userNamespace, data => {
		store.dispatch('webSocketMessageReceived', {
			command: data.command,
			data: data.data
		});
	});
}

export function wsDisconnect() {
	if (socket) {
		socket.disconnect();
	}
}
