export function isSliceExtensionInstalled() {
	// eslint-disable-next-line no-unused-vars
	return new Promise((resolve, reject) => {
		if (!window.chrome || !window.chrome.runtime) {
			resolve(false);
			return;
		}

		window.chrome.runtime.sendMessage(process.env.VUE_APP_WEBCLIPPER_EXTENSION_ID, { message: "version" }, (reply) => {
			if (!reply) {
				resolve(false);
			} else {
				resolve(true);
			}
		});
	});
}

export function isUrlValid(url) {
	if (!url.startsWith('http://') && !url.startsWith('https://')) {
		return false;
	}

	try {
		url = new URL(url);
	} catch (_) {
		return false;
	}

	return url.protocol === "http:" || url.protocol === "https:";
}

export function isChrome() {
	return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
}
