<template lang="pug">
	b-alert(v-model="duration", :variant="config.type", fade, dismissible, @dismissed="dismissed")
		h4 {{config.message}}
</template>

<script>
	import {BAlert} from 'bootstrap-vue';
	import {mapGetters} from "vuex";

	export default {
		name: 'InlineNotificationModal',
		components: {BAlert},
		computed: {
			...mapGetters({config: 'inlineNotificationModalConfig'}),
		},
		data: () => ({
			duration: 0,
		}),
		watch: {
			config(newValue) {
				this.duration = newValue.duration;
			}
		},
		methods: {
			dismissed() {
				this.$store.commit('RESET_INLINE_NOTIFICATION_MODAL_CONFIG');
			}
		},
	};
</script>

<style lang="scss">
.alert-success {
	background-color: #2B8A3E;
	border-color: #2B8A3E;
	z-index: 1200;
}
.alert-danger {
	background-color: #F74646;
	border-color: #F74646;
	z-index: 1200;
}
.alert {
	position: fixed;
	padding: 12px 30px;
	border: 1px solid transparent;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	min-height: 55px;
	border-radius: 27.5px;
	box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
	h4 {
		color: #FFFFFF;
		font-family: 'Metropolis Semibold', sans-serif;
		font-size: 17px;
		letter-spacing: -0.02px;
		line-height: 30px;
		text-align: left;
		margin-bottom: 0;
		font-weight: bold;
		display: inline;
		margin-right: 20px;
	}
	.close {
		line-height: 30px;
		color: #FFFFFF;
		opacity: 1;
		&:hover {
			color: #FFFFFF;
		}
	}
}
</style>
