<template lang="pug">
	.segment-sidebar-container
		slot
</template>

<script>
export default {
	name: 'SegmentSidebarContainer',
	data: () => ({
	}),
	computed: {
	}
};
</script>

<style lang="scss">
</style>
