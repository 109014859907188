<template lang="pug">
	.segment-submenu
		.segment-submenu-header
			segment-menu-item(@click="toggleShow", :class="[hdClass, { active: isActive }]", v-on="$listeners")
				template(v-slot:title)
					slot(name="title") {{ hdLabel }}
				template(v-slot:actions)
					slot(name="actions")
					.toggle-menu(:class="{ show: isShow }", v-if="!hdHideCollapse")
						i.fa.fa-chevron-down

		transition(name="slide-fade")
			.segment-submenu-items(v-show="isShow")
				slot
</template>

<script>
import SegmentMenuItem from './SegmentMenuItem.vue';

export default {
	name: 'SegmentSubmenu',
	components: { SegmentMenuItem },
	props: {
		hdLabel:        { type: String, default: '#SubMenu' },
		hdClass:        { default: '' },
		hdHideCollapse: { type: Boolean, default: false },
		show:           { type: Boolean, default: false },
		active:         { default: null }
	},
	data: () => ({
		isShow: false
	}),
	watch: {
		show: {
			immediate: true,
			handler(newValue) {
				this.isShow = newValue;
			}
		},
		isShow(newValue) {
			const eventName = newValue ? 'show' : 'hide';
			this.$emit(eventName);
			this.$emit('visibility', newValue);
		}
	},
	computed: {
		isActive() {
			if (typeof this.active === 'boolean') {
				return this.active;
			}

			return this.isShow;
		}
	},
	methods: {
		isShowSubmenu() {
			return this.isShow;
		},
		showSubmenu() {
			this.isShow = true;
		},
		hideSubmenu() {
			this.isShow = false;
		},
		toggleShow() {
			this.isShow = !this.isShow;
		}
	}
};
</script>

<style lang="scss">
	.segment-submenu {
		.segment-submenu-items {
			display: flex;
			flex-direction: column;
			padding-left: 10px;
		}

		.segment-menu-item .segment-item-actions .toggle-menu {
			transition: all .2s ease-out;

			&.show {
				transform: rotate(180deg);
			}
		}
	}
</style>
