export function isPageLoading(state) {
	return !!state.pageLoader;
}

export function loadingOverlayState(state) {
	return state.loadingOverlay;
}

export function isWsConnected(state) {
	return state.wsState === 'connected';
}

export function apiErrorModalConfig(state) {
	return state.apiErrorModalConfig;
}

export function inlineNotificationModalConfig(state) {
	return state.inlineNotificationModalConfig;
}
