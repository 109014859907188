<template>
	<div class="app-loader" v-if="state.active">
		<div class="orbit-spinner">
			<div class="orbit"></div>
			<div class="orbit"></div>
			<div class="orbit"></div>
		</div>
		<div class="loader-message">{{ state.message || 'Loading...' }}</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		computed: {
			...mapGetters({state: 'loadingOverlayState'}),
		}
	};
</script>

<style lang="scss">
	@import "src/scss/variables";

	.app-loader {
		background-color: #273f52;
		opacity: 0.9;
		color: $spinner-text-white;
		font-size: 20px;
		text-align: center;
		align-items: center;
		display: flex;
		justify-content: center;
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 350; // header has z-index 300
	}

	.loader-message {
		font-family: 'Metropolis Regular';
		font-size: 17px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.02px;
		color: #ffffff;
	}

	.orbit-spinner, .orbit-spinner * {
		box-sizing: border-box;
	}

	.orbit-spinner {
		height: 55px;
		width: 55px;
		border-radius: 50%;
		perspective: 800px;
		margin-right: 20px;
	}

	.orbit-spinner .orbit {
		position: absolute;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	.orbit-spinner .orbit:nth-child(1) {
		left: 0%;
		top: 0%;
		animation: orbit-spinner-orbit-one-animation 1200ms linear infinite;
		border-bottom: 3px solid $spinner-white;
	}

	.orbit-spinner .orbit:nth-child(2) {
		right: 0%;
		top: 0%;
		animation: orbit-spinner-orbit-two-animation 1200ms linear infinite;
		border-right: 3px solid $spinner-white;
	}

	.orbit-spinner .orbit:nth-child(3) {
		right: 0%;
		bottom: 0%;
		animation: orbit-spinner-orbit-three-animation 1200ms linear infinite;
		border-top: 3px solid $spinner-white;
	}

	@keyframes orbit-spinner-orbit-one-animation {
		0% {
			transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
		}
		100% {
			transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
		}
	}

	@keyframes orbit-spinner-orbit-two-animation {
		0% {
			transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
		}
		100% {
			transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
		}
	}

	@keyframes orbit-spinner-orbit-three-animation {
		0% {
			transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
		}
		100% {
			transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
		}
	}
</style>
