<template lang="pug">
	.audio-controls-container(v-if="trackLoaded")
		.overlay(v-if="!isPaneActive")
		.empty-gap
		.control-buttons
			img.end-btn(src="@/assets/audio-player/icon-player-black-backward-end.png", @click="goToBegin")
			img.seek-btn(src="@/assets/audio-player/icon-player-black-backward-30s.png", @click="move(-30)")
			img.main-control-btn(src="@/assets/audio-player/icon-player-black-play.png", v-if="!isPlaying", @click="play")
			img.main-control-btn(src="@/assets/audio-player/icon-player-black-pause.png", v-else, @click="pause")
			img.seek-btn(src="@/assets/audio-player/icon-player-black-rewind-30s.png", @click="move(30)")
			img.end-btn(src="@/assets/audio-player/icon-player-black-forward-end.png", @click="goToEnd")
		.empty-gap
		.audio-progress-bar
			vue-slider.slider(
				v-model="currentProgressBar",
				:process-style="processStyle",
				:rail-style="barStyle",
				:dot-style="dotStyle",
				:tooltip="'none'",
				@change="trackPositionChanged")
			.time
				.time-value {{ currentTime | formatTime }}
				.time-value {{ trackDuration | formatTime }}
		.empty-gap
		.sound-control
			img.sound-btn(src="@/assets/audio-player/icon-player-black-volume.png")
			.slider-container
				vue-slider(
				v-model="currentVolume",
				:process-style="processStyle",
				:rail-style="barStyle",
				:dot-style="dotStyle",
				:tooltip="'none'",
				@change="volumeChanged")
		.empty-gap
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
	name: 'AudioPlayer',
	components: {
		VueSlider
	},
	props: {
		src: { type: String, required: true },
		paneIdx: { type: Number, required: true },
		projectId: { type: String, required: true }
	},
	data: () => ({
		audio: null,
		isPlaying: false,
		currentTime: 0,
		currentProgressBar: 0,
		trackDuration: 0,
		currentVolume: 100,
		trackLoaded: false,
		barStyle: {
			backgroundColor: 'rgba(23, 41, 55, .5)'
		},
		processStyle: {
			backgroundColor: '#172937'
		},
		dotStyle: {
			backgroundColor: '#172937',
			boxShadow: 'unset'
		},
	}),
	watch: {
		'src': {
			handler: 'audioLoaded'
		}
	},
	beforeDestroy() {
		if (!this.audio) {
			return;
		}

		this.audio.removeEventListener('ended', this.handleEnded);
		this.audio.removeEventListener('loadedmetadata', this.handleEnded);
		this.pause();
	},
	mounted() {
		this.$root.$on('audio-play-start', this.handleAudioStarted);
	},
	filters: {
		formatTime(time) {
			return (time - (time %= 60)) / 60 + (9 < time ? ":" : ":0") + time;
		}
	},
	computed: {
		isPaneActive() {
			const isPaneActive = this.$store.getters['projects/getPaneActiveStatus'](this.projectId, this.paneIdx);
			if (!isPaneActive) {
				this.pause();
			}
			return isPaneActive;
		}
	},
	methods: {
		handleAudioStarted(audioStartedInPaneIdx) {
			if (this.paneIdx !== audioStartedInPaneIdx) {
				this.pause();
			}
		},
		handleEnded() {
			this.pause();
		},
		volumeChanged(e) {
			this.audio.volume = e / 100;
		},
		trackPositionChanged(position) {
			if (isNaN(position)) {
				return;
			}

			const newTime = Math.round(this.audio.duration * position / 100);
			this.audio.currentTime = newTime;
			this.play();
		},
		audioLoaded() {
			this.trackLoaded = false;
			this.audioFile = this.src;
			this.audio = new Audio(this.src);

			const self = this;
			this.audio.addEventListener('loadedmetadata', function() {
				self.trackDuration = Math.round(this.duration);
				self.trackLoaded = true;
			});

			this.audio.addEventListener('ended', () => {
				self.pause();
			});
		},
		play(e) {
			this.audio.play();
			this.isPlaying = true;
			this.updateCurrentTime();
			this.$root.$emit('audio-play-start', this.paneIdx);
		},
		pause() {
			if (!this.audio) {
				return;
			}

			this.audio.pause();
			this.isPlaying = false;
			this.updateCurrentTime();
		},
		goToBegin() {
			this.audio.currentTime = 0;
			this.play();
		},
		goToEnd() {
			this.audio.currentTime = this.audio.duration;
			this.pause();
		},
		move(numberOfSeconds) {
			// If audio reached end, stop the track
			if (this.audio.currentTime + numberOfSeconds >= this.audio.duration) {
				this.audio.currentTime = this.audio.duration;
				this.pause();
			} else {
				this.audio.currentTime += numberOfSeconds;
				this.play();
			}
		},
		updateCurrentTime() {
			clearTimeout(this.checkingCurrentPositionInTrack);
			const self = this;
			this.checkingCurrentPositionInTrack = setTimeout(() => {
				if (!self.audio) {
					return;
				}

				self.currentTime = Math.round(self.audio.currentTime);
				self.currentProgressBar = Math.round(self.audio.currentTime / self.audio.duration * 100);
				self.updateCurrentTime();
			}, 10);
		},
	}
};
</script>

<style lang="scss">
	.overlay {
		position: absolute;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.9);
		opacity: 0.7;
		height: 4.625rem;
		width: 100%;
	}
	.audio-controls-container {
		width: 100%;
		height: 4.625rem;
		background-color: #FFFFFF;
		display: grid;
		grid-template-columns: 40px 184px 40px auto 40px 180px 40px;
		align-items: center;

		.control-buttons {
			.end-btn {
				width: 18px;
				height: 18px;
				&:hover {
					opacity: 1;
				}
			}

			.seek-btn {
				width: 22px;
				height: 25px;
			}

			.main-control-btn {
				width: 40px;
				height: 40px;
			}

			img ~ img {
				margin-left: 16px;

				&:hover {
					opacity: 1;
				}
			}

			img {
				cursor: pointer;
			}
		}

		.audio-progress-bar {
			margin-top: 15px;

			.time {
				display: flex;
				justify-content: space-between;

				.time-value {
					font-family: $proxima-medium;
					font-size: 11px;
					font-weight: 600;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: 0.2px;
					color: #172937;
				}
			}
		}

		.sound-control {
			display: grid;
			grid-template-columns: 22px auto;

			.sound-btn {
				margin-top: 2px;
				width: 21px;
				height: 16px;

				&:hover {
					opacity: 1;
				}
			}

			.slider-container {
				margin-left: 16px;
			}
		}

		.progress-part {
			.time-value {
				color: white;
			}
		}
	}
</style>
