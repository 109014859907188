<template lang="pug">
	div.three-dots-wrapper(@click="toggleMemberActionMenu($event)")
		p.three-dots
			ul.popup(v-bind:style="popupStyle")
				template(v-if="currentUserIsProjectOwner()")
					li(v-if="memberHasOwnerRoleForProject(user)", @click="!isYourself(user) ? removeOwnership(user) : null", v-bind:class="{disabled: isYourself(user)}") {{isYourself(user) ? 'Resign ownership' : 'Remove ownership'}}
					li(v-if="memberHasMemberRoleForProject(user) && !isMembershipPending(user)", @click="addOwnership(user)") Assign ownership
					li(v-if="memberHasOwnerRoleForProject(user)", @click="ableToRemoveOwner ? removeMember(user) : null", v-bind:class="{disabled: !ableToRemoveOwner}") {{isYourself(user) ? 'Leave project' : 'Remove from project'}}
					li(v-if="memberHasMemberRoleForProject(user)", @click="removeMember(user)") Remove from project
				template(v-if="currentUserIsProjectMember()")
					li(v-if="memberHasOwnerRoleForProject(user)", v-bind:class="{disabled: currentUserIsProjectMember()}") Remove ownership
					li(v-if="memberHasMemberRoleForProject(user)", v-bind:class="{disabled: currentUserIsProjectMember()}") Assign ownership
					li(@click="isYourself(user) ? removeMember(user) : null", v-bind:class="{disabled: !isYourself(user)}") {{isYourself(user) ? 'Leave project' : 'Remove from project'}}
</template>

<script>
	import {mapGetters} from "vuex";

	export default {
		props: {
			source: String,
			user: {
				type: Object,
				required: true,
			},
			project: {
				type: Object,
				required: true,
			},
			canRemoveOwner: {
				type: Boolean,
				required: true,
			}
		},
		data: () => ({
			popupStyle: {
				display: 'none',
				top: 0,
				left: 0,
			},
			currentUser: {},
			ableToRemoveOwner: false
		}),
		watch: {
			canRemoveOwner: {
				immediate: true,
				handler(newValue) {
					this.ableToRemoveOwner = newValue;
				}
			},
		},
		computed: {
			...mapGetters({account: 'user/account'}),
		},
		methods: {
			currentUserIsProjectOwner() {
				return this.project && this.project.userRole === 'owner';
			},
			currentUserIsProjectMember() {
				return this.project && this.project.userRole === 'member';
			},
			memberHasOwnerRoleForProject(member) {
				return member.role === 'owner';
			},
			memberHasMemberRoleForProject(member) {
				return member.role === 'member';
			},
			isMembershipPending(member) {
				return member.status === 'invitePending';
			},
			toggleMemberActionMenu(event) {
				this.popupStyle.display = this.popupStyle.display === 'block' ? 'none' : 'block';
				if (this.popupStyle.display === 'block') {
					this.popupStyle.left = (event.clientX + 20) + 'px';
					this.popupStyle.top = event.clientY + 'px';
				}
			},
			removeMember(user) {
				const data = {
					projectId: this.project._id,
					userId: user.id,
					email: encodeURIComponent(user.email)
				};
				this.$store.dispatch('projects/removeUser', data)
					.then(response => {
						this.$mixPanel.trackEvent('Remove From Project', this.source, 'Project Members');
						if (this.isYourself(user)) {
							this.$store.commit('projects/REMOVED_FROM_PROJECT', true);
							this.$store.commit(
								'projects/REMOVED_FROM_PROJECT_MESSAGE',
								`You have successfully removed yourself from project ${this.project.name}.`
							);
							if (this.$route.path === '/projects') {
								this.$emit('close-modal');
							}
							return;
						}
						this.$emit('fetch-members');
						this.$emit(
							'show-message',
							{duration: 5, type: 'success', message: `You have successfully removed ${user.name ? user.name : user.email} from ${this.project.name}.`}
						);
						this.$root.$emit('workbench:refresh');
					});
			},
			addOwnership(user) {
				this.changeMemberRole(user, 'owner')
					.then(x => {
						this.$mixPanel.trackEvent('Assign Ownership', this.source, 'Project Members');
					});
			},
			removeOwnership(user) {
				this.changeMemberRole(user, 'member')
					.then(x => {
						this.$mixPanel.trackEvent('Resign Ownership', this.source, 'Project Members');
					});
			},
			changeMemberRole(user, role) {
				return this.$store.dispatch(
					'projects/changeUserRole',
					{projectId: this.project._id ,email: user.email, role: role}
				)
					.then(result => {
						this.$emit('fetch-members');
						const message = role === 'owner'
							? `You've made ${user.name} an owner of this project. Now they can invite others, too!`
							: `You've made ${user.name} a teammate on this project.`;
						this.$emit('show-message', {duration: 5, type: 'success', message: message});
					})
					.catch(err => this.$swal.error('Error', err.message));
			},
			closePopupOnOutsideClick(event) {
				if (!this.$el.contains(event.target)){
					this.popupStyle.display = 'none';
				}
			},
			isYourself(user) {
				return this.currentUser.id === user.id;
			},
		},
		created() {
			this.currentUser = this.account;
			document.addEventListener('click', e => this.closePopupOnOutsideClick(e));
		},
		beforeDestroy() {
			document.removeEventListener('click', e => this.closePopupOnOutsideClick(e));
		}
	};
</script>

<style lang="scss">
	.popup {
		font-family: 'Proxima Nova Medium', sans-serif;
		z-index: 1111;
		position: fixed;
		background-color: #FFFFFF;
		padding: 10px 20px;
		margin: 0;
		border-radius: 4px;
		box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.15);
		cursor: default;
		list-style: none;
		li {
			height: 30px;
			padding: 5px;
			font-size: 15px;
			color: #172937;
			cursor: pointer;
			&.disabled {
				cursor: text;
				color: #DAD4DF;
			}
		}
	}
	.three-dots-wrapper {
		cursor: pointer;
		.three-dots {
			height: 15px;
			width: 3px;
			opacity: 1;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			background-image: url('~@/assets/panel/Dots.svg');
			padding: 0;
			margin: auto;
		}
	}
</style>
