<template lang="pug">
	.segment-header(v-bind:class="{shadow: shadow}")
		slot
</template>

<script>
export default {
	props: {
		shadow: {type: Boolean, default: false, required: true}
	},
	name: 'SegmentHeader',
	data: () => ({
	}),
	computed: {
	}
};
</script>

<style lang="scss">
	.segment-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 10px 20px;
		&.shadow {
			-webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2);
			-moz-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2);
			box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2);
			z-index: 10;
		}
	}
</style>
