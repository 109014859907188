export default () => ({
	token: null,
	apikey: null,
	account: {
		username: 'anon.',
		roles: ['ROLE_ANON']
	},
	auth: false,
	displayInterstitialPage: true,
	displayFictionChoicePage: true,
});
