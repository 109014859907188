export default () => ({
	displayList: [],
	mapSegments: {},
	mapResources: {},
	mapLayout: {},
	isSegmentsLoading: false,
	isResourcesLoading: false,
	updatedEntity: {},
	removedFromProject: false,
	removedFromProjectMessage: '',
	projectUpdated: {}
});
