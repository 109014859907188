import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';
import defaultState from './state';
import {get} from 'lodash';

export default {
	namespaced: true,
	state: defaultState(),
	actions,
	getters: {
		...getters,
		getPaneActiveStatus: (state) => (projectId, paneIndex) => {
			return get(state?.mapLayout[projectId]?.panes[paneIndex], 'active', false);
		},
		getCursorPosition: (state) => (projectId, entityId, paneIdx) => {
			const item = state.mapLayout[projectId];
			if (!item) {
				return null;
			}

			if (item.cursorPosition && item.cursorPosition[entityId]) {
				return item.cursorPosition[entityId][paneIdx];
			}

			return null;
		},
		getPanes: (state) => (projectId) => {
			return get(state?.mapLayout[projectId], 'panes', []);
		},
		getActivePaneIndex: (state) => (projectId) => {
			const panes = get(state?.mapLayout[projectId], 'panes', []);
			const paneIndex = panes.findIndex(pane => pane.active);
			return paneIndex > -1 ? paneIndex : 0;
		},
		getViewMode: (state) => (projectId) => {
			return get(state?.mapLayout[projectId], 'viewMode', 2);
		},
	},
	mutations,
};
