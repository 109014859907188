export default {
	data() {
		return {
			tenant: process.env.VUE_APP_OUTSETA_TENANT,
			planDefinitions: JSON.parse(process.env.VUE_APP_OUTSETA_PLAN_DEFINITIONS)
		};
	},
	computed: {
		outsetaSignInUrl() {
			return `https://${this.tenant}.outseta.com/auth?widgetMode=login#o-anonymous`;
		},
		outsetaAuthScript() {
			return `https://${this.tenant}.outseta.com/Scripts/client/dist/outseta.auth.widget.min.js`;
		},
		outsetaAuthScriptSelector() {
			return `a[href^='https://${this.tenant}.outseta.com/widgets/auth']`;
		},
		outsetaProfileScript() {
			return `https://${this.tenant}.outseta.com/Scripts/client/dist/outseta.profile.widget.min.js`;
		},
		outsetaProfileScriptSelector() {
			return `a[href^='https://${this.tenant}.outseta.com/widgets/profile']`;
		},
		outsetaSupportUrl() {
			return `https://support.slice.tech/support/kb`;
		},
		outsetaProfileUrl() {
			return `https://${this.tenant}.outseta.com/profile?#o-authenticated`;
		},
		outsetaChangePlanProfileUrl() {
			return `https://${this.tenant}.outseta.com/profile?tab=planChange#o-authenticated`;
		}
	},
	methods: {
		getPreselectedPlan(family) {
			let preselectedPlan = this.planDefinitions.find(x => x.target === family);
			if (!preselectedPlan) {
				const defaultPlan = 'temp-family-1';
				preselectedPlan = this.planDefinitions.find(x => x.target === defaultPlan);
			}

			return `https://${this.tenant}.outseta.com/auth?widgetMode=register&planUid=${preselectedPlan.preselectedPlanId}&planPaymentTerm=month&skipPlanOptions=true#o-anonymous`;
		}
	}
};
