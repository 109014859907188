<template lang="pug">
	.segment-menu
		slot
</template>

<script>
export default {
	name: 'SegmentMenu',
	data: () => ({
	}),
	computed: {
	}
};
</script>

<style lang="scss">
	.segment-menu {
		display: flex;
		flex-direction: column;
		overflow: auto;
		flex: 1;

		&.disabled {
			opacity: .7;
			pointer-events: none;
		}
	}
</style>
