<template lang="pug">
	#app(v-if="isDesktop" :class="appClass")
		router-view(:key="$route.fullPath")
		app-loading-overlay
		api-error-modal
		inline-notification-modal
	.mobile(v-else)
		mobile-view
</template>

<script>
import "@/scss/layout.scss";
import MobileView from "./components/MobileView";
import AppLoadingOverlay from "./components/AppLoadingOverlay";
import { wsDisconnect } from "../src/lib/wss";
import ApiErrorModal from "@/_projects/ApiErrorModal";
import InlineNotificationModal from "@/_projects/InlineNotificationModal";

export default {
	name: "App",
	components: {
		ApiErrorModal,
		MobileView,
		AppLoadingOverlay,
		InlineNotificationModal,
	},
	computed: {
		appClass() {
			return this.$route.meta.appClass || "";
		},
		bodyClass() {
			return this.$route.meta.bodyClass || "";
		},
		authenticationExecuted() {
			return this.$store.state["authenticationExecuted"];
		},
	},
	watch: {
		bodyClass: {
			immediate: true,
			handler(newValue, oldValue) {
				oldValue = oldValue || "";
				newValue = newValue || "";

				const newClass = [
					document.body.className.replace(oldValue, ""),
					newValue,
				]
					.filter((v) => v.length)
					.join(" ");

				document.body.className = newClass;
			},
		},
		authenticationExecuted: {
			immediate: true,
			handler(newValue) {},
		},
	},
	data: () => ({
		isDesktop: true,
	}),
	async mounted() {
		window.addEventListener("online", () => {
			this.$store.dispatch("appOnline");
		});

		window.addEventListener("offline", () => {
			this.$store.dispatch("appOffline");
		});

		if (window.navigator.onLine) {
			this.$store.dispatch("appOnline");
		}
	},
	methods: {
		/**
		 * Since we are using vuex-persistedstate package we need to handle
		 * persisted state separately for every tab containing Slice application,
		 * otherwise authentication problems may arise.
		 * @see https://slice.atlassian.net/browse/SLCE-252
		 * @see https://github.com/robinvdvleuten/vuex-persistedstate
		 */
		addStorageListener() {
			window.addEventListener("storage", (e) => {
				e.stopPropagation();
				try {
					const stateKey = process.env.VUE_APP_PERSISTED_STATE_KEY;
					if (e.key === stateKey) {
						const currentUser = this.getLocalStorageUser(stateKey);
						if (
							!this.authenticationExecuted &&
							currentUser &&
							currentUser.auth &&
							currentUser.apikey &&
							currentUser.token &&
							currentUser.account &&
							currentUser.account.fullName
						) {
							this.$store.commit("SET_AUTH_EXECUTED", true);
							this.$store.commit("user/SET_APIKEY", currentUser.apikey);
							this.$store.commit("user/SET_TOKEN", currentUser.token);
							this.$store.commit("user/SET_ACCOUNT", currentUser.account);

							const outsetaAccessToken = this.$route.query.access_token;
							if (this.$route.path === "/signup" || outsetaAccessToken) {
								return;
							}

							if (this.$route.path === "/hello") {
								return;
							}

							if (this.$route.path !== "/projects") {
								this.$router.push({ name: "projects" }).catch((error) => {
									if (
										error &&
										Object.prototype.hasOwnProperty.call(error, "name") &&
										error.name !== "NavigationDuplicated"
									) {
										throw error;
									}
								});
							}
						} else if (
							this.authenticationExecuted &&
							currentUser &&
							!currentUser.auth &&
							!currentUser.apikey &&
							!currentUser.token
						) {
							this.$store.commit("SET_AUTH_EXECUTED", false);
							this.$store.commit("user/LOGOUT");
							sessionStorage.clear();
							if (this.$route.path !== "/signin") {
								this.$router.push({ name: "signup" }).catch((error) => {
									if (
										error &&
										Object.prototype.hasOwnProperty.call(error, "name") &&
										error.name !== "NavigationDuplicated"
									) {
										throw error;
									}
								});
							}
						}
					}
				} catch (e) {
					console.log("storage listener error", e);
				}
			});
		},
		getLocalStorageUser(key) {
			const currentStorageState = JSON.parse(localStorage.getItem(key));
			return currentStorageState &&
				Object.prototype.hasOwnProperty.call(currentStorageState, "user")
				? currentStorageState.user
				: null;
		},
	},
	created() {
		this.$store.commit("SET", ["closeWarning", null]);
		const desktopSizeStartsAt = 768;
		this.isDesktop = window.innerWidth >= desktopSizeStartsAt;
		addEventListener("resize", () => {
			this.isDesktop = window.innerWidth >= desktopSizeStartsAt;
		});
		window.onbeforeunload = (event) => {
			wsDisconnect();
			const displayMessage = this.$store.state.closeWarning;

			if (typeof displayMessage === "string") {
				if (event) {
					event.returnValue = displayMessage;
				}

				return displayMessage;
			}
		};
		this.addStorageListener();
	},
};
</script>

<style lang="scss">
html,
body {
	margin: 0;
	padding: 0;
	overscroll-behavior-y: none;
	overscroll-behavior-x: none;
}

#app {
	min-height: calc(100vh);
	position: relative;
	margin: 0;
	padding: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.ps {
	position: relative;
}
</style>
