<template lang="pug">
	.icon(:class="[`icon--${name}`, `icon--${size}`, { 'has-align-fix': fixAlign }]")
		img.icon__svg(:src="iconPath")
</template>

<script>
export default {
	props: {
		name: {},
		size: {
			default: 'normal',
		},
		modifier: {
			default: null,
		},
		fixAlign: {
			default: true,
		}
	},
	computed: {
		iconPath() {
			const path = require(`@/assets/pm-editor/icons/${this.name}.svg`);
			return path;
		}
	}
};

</script>

<style lang="scss" scoped>
	.icon {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 0.8rem;
		height: 0.8rem;
		margin: 0 .3rem;
		top: -.05rem;
		fill: currentColor;

		&.has-align-fix {
			top: -.1rem;
		}

		&__svg {
			display: inline-block;
			vertical-align: top;
			width: 100%;
			height: 100%;
		}

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
</style>
