<script>
import _get from 'lodash/get';

import ProjectWorkbenchSplitpanes from './ProjectWorkbenchSplitpanes.vue';
import ProjectWorkbenchPane from './ProjectWorkbenchPane.vue';
import ProjectWorkbenchPaneAddSegment from './ProjectWorkbenchPaneAddSegment.vue';
import ProjectWorkbenchPaneResource from './ProjectWorkbenchPaneResource.vue';
import ProjectWorkbenchPaneSegment from './ProjectWorkbenchPaneSegment.vue';

export default {
	name: 'ProjectWorkbenchLayout',
	components: {
		Segment: ProjectWorkbenchPaneSegment,
		AddSegment: ProjectWorkbenchPaneAddSegment,
		Resource: ProjectWorkbenchPaneResource,
	},
	props: {
		panes:     { type: Array, default: () => [] },
		projectId: { type: String, default: null },
		view:      { type: Number, default: 2 }
	},
	data: () => ({
	}),
	render(createElement) {
		const getPaneComp = (num) => {
			const s = _get(this.panes, [num, 'entityType']) || ProjectWorkbenchPaneResource;
			return s;
		};

		const getPaneAttrs = (num) => {
			const result = {
				...(this.panes[num] ? this.panes[num] : {})
			};

			if (!result.attrs) {
				result.attrs = {};
			}

			if (!result.class) {
				result.class = {};
			}

			result.attrs = {
				projectId: this.projectId,
				paneIdx: num,
				...result.attrs
			};

			if (Array.isArray(result.class)) {
				result.class.push(`pane-${num}`);
			} else {
				result.class[`pane-${num}`] = true;
			}

			return result;
		};

		switch (this.view) {
			case 2: {
				const compA = createElement(getPaneComp(0), getPaneAttrs(0));
				const compB = createElement(getPaneComp(1), getPaneAttrs(1));

				const result = createElement(ProjectWorkbenchSplitpanes, { class: 'layout-2' } , [
					compA,
					compB
				]);
				return result;
			}


			case 3:
				return createElement(ProjectWorkbenchSplitpanes, { class: 'layout-3' } , [
					createElement(getPaneComp(0), getPaneAttrs(0)),

					createElement(ProjectWorkbenchPane, { attrs: { hideHeader: true, dragArea: false } }, [
						createElement(ProjectWorkbenchSplitpanes, { attrs: { horizontal: true } } , [
							createElement(getPaneComp(1), getPaneAttrs(1)),
							createElement(getPaneComp(2), getPaneAttrs(2))
						])
					])
				]);

			default:
				return createElement(ProjectWorkbenchSplitpanes, { class: 'layout-1 hide-pane-header-close' }, [
					createElement(getPaneComp(0), getPaneAttrs(0))
				]);
		}
	}
};
</script>

<style lang="scss">
</style>
