import Promise from 'bluebird';
import _get from 'lodash/get';
import $http from '@/lib/axios';
import $swal from '@/lib/swal';

export function fetchProjects({ dispatch, commit }) {
	const apiUrl = 'v1/projects';
	const params = Object.assign(
		{ limit: 1000, page: 1 },
		{}
	);

	dispatch('user/refresh', null, { root: true });
	dispatch('pageLoader', true, { root: true });
	return $http.get(apiUrl, {params}).then(response => {
		response.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
		commit('SET_DISPLAY_LIST', response);
		dispatch('pageLoader', false, { root: true });
	}).catch(err => {
		commit('SET_DISPLAY_LIST', []);
		dispatch('pageLoader', false, { root: true });
		throw err;
	});
}

export function createProject({ commit }, params = {}) {
	const apiUrl = `v1/projects`;
	const { name, description } = {...params};
	return $http.post(apiUrl, {name, description}).then(result => {
		commit('ADD_PROJECT', result);
		return result;
	}).catch(err => {
		throw err;
	});
}

export function createSampleProjects({ commit }, sampleProjectsType) {
	const apiUrl = `v1/projects/sampleProjects`;
	return $http.post(apiUrl, {sampleProjectsType}).then(result => {
		return result;
	}).catch(err => {
		throw err;
	});
}

export function restartTutorial({ commit }) {
	const apiUrl = `v1/projects/restartTutorial`;
	return $http.post(apiUrl).then(result => {
		return result;
	}).catch(err => {
		throw err;
	});
}

export function archiveProjects({ dispatch, commit }, projectIds) {
	const apiUrl = `v1/projects/archive`;
	return $http.post(apiUrl, projectIds).then(result => {
		dispatch('fetchProjects');
		return result;
	}).catch(err => {
		throw err;
	});
}

export function duplicateProject({ commit }, data = {}) {
	const{uuid, params} = data;
	const apiUrl = `v1/projects/${uuid}/duplicate`;
	return $http.post(apiUrl, {...params}).then(result => {
		commit('ADD_PROJECT', result);
		return result;
	}).catch(err => {
		throw err;
	});
}

export function addUser({ commit }, data = {}) {
	const{ projectId, email, role } = data;
	const apiUrl = `v1/projects/${projectId}/user`;
	return $http.post(apiUrl, {
		email,
		role
	}).then(result => {
		return result;
	}).catch(err => {
		throw err;
	});
}

export function removeUser({ commit }, data = {}) {
	const{ projectId, userId, email } = data;
	const apiUrl = `v1/projects/${projectId}/user?userId=${userId}&email=${email}`;
	return $http.delete(apiUrl).then(result => {
		return result;
	}).catch(err => {
		throw err;
	});
}

export function getProjectUsers({ commit }, data = {}) {
	const{ projectId } = data;
	const apiUrl = `v1/projects/${projectId}/user`;
	return $http.get(apiUrl).then(result => {
		return result;
	}).catch(err => {
		throw err;
	});
}

export function inviteUser({ commit }, params = {}) {
	const {projectId, email} = params;
	const apiUrl = `v1/projects/${projectId}/user/invite`;
	return $http.post(apiUrl, {email}).then(result => {
		return result;
	}).catch(err => {
		throw err;
	});
}

export function changeUserRole({ commit }, params = {}) {
	const {projectId, email, role} = params;
	const apiUrl = `v1/projects/${projectId}/user`;
	return $http.put(apiUrl, {email, role}).then(result => {
		return result;
	}).catch(err => {
		throw err;
	});
}

export function removeProject({ dispatch, commit }, params = {}) {
	const apiUrl = `v1/projects/${params.projectId}`;

	return $http.delete(apiUrl, params.data).then(result => {
		commit('REMOVE_PROJECT', params.projectId);
		dispatch('fetchProjects');
		return result;
	}).catch(err => {
		throw err;
	});
}

export function updateProject({ commit }, params = {}) {
	if (!params.projectId) {
		return;
	}
	const apiUrl = `v1/projects/${params.projectId}`;

	return $http.put(apiUrl, params.data).then(result => {
		commit('UPDATE_PROJECT', result);
		return result;
	}).catch(err => {
		throw err;
	});
}

export function addSegment({ dispatch, commit }, params = {}) {
	const apiUrl = `v1/projects/${params.projectId}/items`;
	const payload = params.data || {};
	payload.group = params.data.group || 'content';
	payload.type = params.data.type || 'prosemirror';

	let result = null;

	commit('SET', ['isSegmentsLoading', true]);

	return $http.post(apiUrl, payload)
		.then((segment) => {
			commit('SET', ['isSegmentsLoading', false]);
			result = segment;
			commit('ADD_SEGMENT', {params, segment});
		})
		.then(() => result)
		.catch(err => {
			commit('SET', ['isSegmentsLoading', false]);
			throw err;
		});
}

export function removeSegment({ dispatch }, params = {}) {
	const { projectId, segmentId } = params;
	const apiUrl = `v1/projects/${projectId}/items/${segmentId}`;

	return $http.delete(apiUrl);
}

export async function updateSegment({ dispatch, commit }, params = {}) {
	const { projectId, segmentId } = params;
	const payload = params.data || {};
	const fetch = params.fetch || false;
	payload.group = params.data.group || 'content';
	payload.type = params.data.type;

	const apiUrl = `v1/projects/${projectId}/items/${segmentId}`;

	commit('SET', ['isSegmentsLoading', true]);

	await $http.put(apiUrl, payload);
	if (fetch) {
		await dispatch('fetchResources', { projectId });
		await dispatch('fetchSegments', { projectId });
	}

}

export function fetchSegments({ commit }, { projectId }) {
	const apiUrl = `v1/projects/${projectId}/items?group=content`;

	return $http.get(apiUrl).then(response => {
		commit('SET_MAP_SEGMENTS', [projectId, response]);
		commit('SET', ['isSegmentsLoading', false]);
		return response;
	}).catch(err => {
		commit('SET', ['isSegmentsLoading', false]);
		throw err;
	});
}

export function fetchResources({ commit }, { projectId }) {
	const apiUrl = `v1/projects/${projectId}/items?group=resource`;

	commit('SET', ['isResourcesLoading', true]);

	return $http.get(apiUrl).then(response => {
		commit('SET_MAP_RESOURCES', [projectId, response]);
		commit('SET', ['isResourcesLoading', false]);
		return response;
	}).catch(err => {
		commit('SET', ['isResourcesLoading', false]);
		throw err;
	});
}

export function addResource({ dispatch, commit }, params = {}) {
	const apiUrl = `v1/projects/${params.projectId}/items`;
	const payload = params.data || {};

	let result = null;

	commit('SET', ['isResourcesLoading', true]);

	const formData = new FormData();
	formData.append('file', payload.file);
	formData.append('group', params.data.group || 'resource');
	formData.append('type', params.data.type || 'pdf');
	formData.append('name', payload.name);

	return $http.post(apiUrl, formData)
		.then((resource) => {
			result = resource;
			commit('SET', ['isResourcesLoading', false]);
			commit('ADD_RESOURCE', {params, resource});
		})
		.then(() => result)
		.catch(err => {
			commit('SET', ['isResourcesLoading', false]);
			throw err;
		});
}

export async function updateResource({ dispatch, commit }, params = {}) {
	const { projectId, resourceId, isReorder} = params;
	const payload = params.data || {};
	const fetch = params.fetch || false;
	payload.group = payload.group || 'resource';

	const apiUrl = `v1/projects/${projectId}/items/${resourceId}`;

	commit('SET', ['isResourcesLoading', true]);

	if (isReorder) {
		await $http.put(apiUrl, params.data);
	} else {
		const formData = new FormData();
		formData.append('file', payload.file);
		formData.append('group', params.data.group || 'resource');
		formData.append('type', params.data.type);
		formData.append('name', params.data.name);

		await $http.put(apiUrl, formData);
	}
	commit('SET', ['isResourcesLoading', false]);
	if (fetch) {
		await dispatch('fetchSegments', { projectId });
		await dispatch('fetchResources', { projectId });
	}
}

// eslint-disable-next-line no-unused-vars
export function getResourceContent({commit}, params = {}) {
	const { projectId, resourceId } = params;
	const payload = params.data || {};
	const apiUrl = `v1/projects/${projectId}/items/${resourceId}/content`;
	return $http.get(apiUrl, payload);
}

export function checkItem({commit}, params = {}) {
	const { projectId, resourceId } = params;
	const payload = params.data || {};
	const apiUrl = `v1/projects/${projectId}/items/${resourceId}/check`;
	return $http.get(apiUrl, payload);
}

export function removeResource({ dispatch }, params = {}) {
	const { projectId, resourceId } = params;
	const apiUrl = `v1/projects/${projectId}/items/${resourceId}`;

	return $http.delete(apiUrl);
}

export function initMainLayout({ commit }, projectId) {
	commit('SET_MAP_LAYOUT_VIEW_MODE', [projectId, 2]);
	commit('SET_MAP_LAYOUT_PANES', [projectId, [
		{ entityType: 'resource', active: true },
		{ entityType: 'resource', active: false },
		{ entityType: 'audio', active: false }
	]]);
}

export function pushLayoutState({ state }, projectId) {
	const panes    = _get(state, ['mapLayout', projectId, 'panes'], []);
	const viewMode = _get(state, ['mapLayout', projectId, 'viewMode'], 1);

	const getPaneParams = idx => {
		const entityType = _get(panes, [idx, 'entityType']);
		const entityId   = _get(panes, [idx, 'attrs', 'entityId']);
		const active     = _get(panes, [idx, 'active'], false);

		if (entityId && entityType) {
			return { entityId, entityType, active };
		}
	};

	const params = {
		currentLayout: viewMode,
		panes: {
			first: getPaneParams(0),
			second: getPaneParams(1),
			third: getPaneParams(2)
		}
	};

	// eslint-disable-next-line no-unused-vars
	return $http.put(`v1/projects/${projectId}`, params).catch(err => {
	});
}

export function isItemInPane({state}, {projectId, itemId}) {
	const panes = _get(state, ['mapLayout', projectId, 'panes'], []);
	return panes.findIndex(pane => pane.attrs.entityId === itemId);
}

export function sendInviteEmail({ commit }, data = {}) {
	const{ projectId, email, role } = data;
	const apiUrl = `v1/projects/${projectId}/user/invite`;
	return $http.post(apiUrl, {
		email: email,
		role: role
	}).then(result => {
		return result;
	}).catch(err => {
		throw err;
	});
}

export function fetchLayoutState({ commit, dispatch }, projectId) {
	return $http.get(`v1/projects/${projectId}`).then(project => {
		const viewMode = _get(project, 'currentLayout', 2);
		const mapPanes = _get(project, 'panes', {});

		const panesList = [
			mapPanes.first,
			mapPanes.second,
			mapPanes.third
		].map((v, index) => {
			if (!v) return null;

			return {
				active: v.hasOwnProperty('active') ? v.active : index === 0,
				entityType: v.entityType,
				attrs: { entityId: v.entityId }
			};
		});

		commit('SET_MAP_LAYOUT_PANES', [projectId, panesList]);
		commit('SET_MAP_LAYOUT_VIEW_MODE', [projectId, viewMode]);
	});
}

export function updateLayoutPane({ commit, dispatch }, { projectId, paneIdx, newState = {} }) {
	switch (true) {
		case typeof projectId !== 'string' || !projectId:
			throw new TypeError('missed or invalid project id.');

		case typeof newState.entityType !== 'string':
			throw new TypeError('newState.entityType invalid string.');

		case typeof paneIdx !== 'number' || paneIdx < 0 || paneIdx > 2:
			throw new TypeError('newState.paneIdx invalid number.');
	}

	if (!newState.attrs) {
		newState.attrs = {};
	}

	// TOO-DO: validation

	commit('UPDATE_MAP_LAYOUT_PANE_BY_IDX', [projectId, paneIdx, newState]);
	dispatch('pushLayoutState', projectId);
}

export function closeLayoutPane({ commit, state, dispatch }, { projectId, paneIdx }) {
	switch (true) {
		case typeof projectId !== 'string' || !projectId:
			throw new TypeError('missed or invalid project id.');

		case typeof paneIdx !== 'number' || paneIdx < 0 || paneIdx > 2:
			throw new TypeError('paneIdx invalid number.');
	}

	const panes    = _get(state, ['mapLayout', projectId, 'panes'], []);
	const viewMode = _get(state, ['mapLayout', projectId, 'viewMode'], 1);

	if (viewMode <= 1) {
		throw new Error('No way to close latest pane');
	}

	const closedPane = panes[paneIdx] || null;

	const newPanes = [...panes];
	newPanes.splice(paneIdx, 1);
	newPanes.push({active: false, entityType: null, attrs: {entityId: null}});
	const activePaneIndex = panes.findIndex(pane => !!pane.active);
	if (activePaneIndex < 0) {
		newPanes[0].active = true;
	}
	const newViewMode = viewMode - 1;

	commit('SET_MAP_LAYOUT_PANES', [projectId, newPanes]);
	commit('SET_MAP_LAYOUT_VIEW_MODE', [projectId, newViewMode]);

	if (closedPane) {
		// We no longer need to have recently closed projects
		//commit('ADD_MAP_RECENT_CLOSED_PANE', [projectId, closedPane]);
	}

	dispatch('pushLayoutState', projectId);
}

export function removeItemFromPane({ commit, state, dispatch }, { projectId, paneIdx }) {
	switch (true) {
		case typeof projectId !== 'string' || !projectId:
			throw new TypeError('missed or invalid project id.');

		case typeof paneIdx !== 'number' || paneIdx < 0 || paneIdx > 2:
			throw new TypeError('paneIdx invalid number.');
	}
	const newState = {active: true, entityType: '', attrs: {entityId: null}};
	commit('UPDATE_MAP_LAYOUT_PANE_BY_IDX', [projectId, paneIdx, newState]);
	const panes = _get(state, ['mapLayout', projectId, 'panes'], []);
	const activePaneIndex = panes.findIndex(pane => !!pane.active);
	if (activePaneIndex < 0) {
		panes[0].active = true;
	}
	commit('SET_MAP_LAYOUT_PANES', [projectId, panes]);
	dispatch('pushLayoutState', projectId);
}

export function setPaneActive({commit, dispatch}, {projectId, paneIndex}) {
	commit('SET_PANE_ACTIVE', [projectId, paneIndex]);
	dispatch('pushLayoutState', projectId);
}

export function updateLayoutPaneStateAfterRemoving({ commit, state, dispatch }, { projectId, entityId }) {
	const panes = _get(state, ['mapLayout', projectId, 'panes'], []);
	const resourceInPanes = panes.filter(x => x.attrs && x.attrs.entityId === entityId);

	resourceInPanes.forEach(item => {
		const paneIdx = panes.findIndex(x => x === item);
		const pane = panes[paneIdx];

		dispatch('updateLayoutPane', {projectId, paneIdx, newState: {
			active: pane.active,
			entityType: pane.entityType,
			attrs: {
				entityId: null
			}}});
		});
}

export function manageProjectDialog({ dispatch }, params = {}) {
	const imagePath = require('../../assets/illustrations/typing.svg');

	const { id, name, description } = params;
	let dialogTitle, confirmButtonTitle,
		projectNameLabel, projectName,
		projectDescriptionLabel, projectDescription;

	if (!id) {
		dialogTitle = 'Create a new project';
		confirmButtonTitle = 'Create project';

		projectNameLabel = 'Enter your project name';
		projectDescriptionLabel = 'Enter a description (optional)';
		projectName = '';
		projectDescription = '';
	} else {
		dialogTitle = 'Rename this project';
		confirmButtonTitle = 'Rename project';

		projectNameLabel = 'Revise your project name';
		projectDescriptionLabel = 'Revise your description (optional)';
		projectName = name;
		projectDescription = description;
	}

	return $swal.fire({
		title: `${dialogTitle}`,
		position: 'center',
		html: `
			<form class="create-new-project">
				<div class="form-group">
					<label>
						${projectNameLabel}
					</label>
					<input id="projectName" type="text" value="${projectName}" name="projectName" placeholder="Start typing here..." autocomplete="disabled" class="form-control app-swal-input" />
					<div id="projectNameFeedback" class="swal2-validation-message">Project name is required</div>
				</div>
				<div class="form-group">
					<label>
						${projectDescriptionLabel}
					</label>
					<input id="projectDescription" type="text" value="${projectDescription}" name="projectDescription" placeholder="Start typing here..." autocomplete="disabled" class="form-control app-swal-input" />
					<div class="swal2-validation-message"></div>
				</div>
				<img class="illustration" src="${imagePath}"/>
			</form>
		`,
		showCancelButton: true,
		showCloseButton: false,
		showLoaderOnConfirm: false,
		reverseButtons: true,
		customClass: {
			confirmButton: 'btn btn-app-blue2 sm',
			cancelButton: 'btn-link sm absolute-position',
			header: 'right',
		},
		buttonsStyling: false,
		confirmButtonText: confirmButtonTitle,
		preConfirm: () => {
			document.getElementById('projectNameFeedback').style.display = 'none';
			const projectName = document.getElementById('projectName').value;
			const projectDescription = document.getElementById('projectDescription').value;

			if (!projectName) {
				document.getElementById('projectNameFeedback').style.display = 'block';
				return false;
			}

			if (!id) {
				return dispatch('createProject', {
					name: projectName,
					description: projectDescription
				}).catch(e => {
					return false;
				});
			}

			const updateParams = {
				projectId: id,
				data: {
					name: projectName,
					description: projectDescription
				}
			};
			return dispatch('updateProject', updateParams);
		},
		onOpen: () => {
			const projectNameInputField = document.getElementById('projectName');
			projectNameInputField.focus();
			// Set cursor at the end
			if (typeof projectNameInputField.selectionStart == "number") {
				projectNameInputField.selectionStart = projectNameInputField.selectionEnd = projectNameInputField.value.length;
			} else if (typeof projectNameInputField.createTextRange != "undefined") {
					var range = projectNameInputField.createTextRange();
					range.collapse(false);
					range.select();
			}
		}
	}).then(r => {
		if (r && (r.dismiss === 'cancel' || r.dismiss === 'overlay' || r.dismiss === 'backdrop')) {
			return false;
		}

		return r.value;
	}).catch(err => {
		if (err === 'cancel') return false;
	});
}

export function removeProjectDialog({ dispatch }, { projectId, projectName }) {
	const imagePath = require('../../assets/illustrations/paper-shredder.png');

	return $swal.fire({
		title: 'Delete this project?',
		position: 'center',
		html: `
			<div class="delete-project-content">
				<img class="illustration" src="${imagePath}"/>
				<div class="message-content">
					<p>
						Are you sure you want to delete <span class="bold">${projectName}</span>?
					</p>
					<p>
						This is permanent, and the project cannot be recovered.
					</p>
				</div>
			</div>
		`,
		showCancelButton: true,
		showCloseButton: false,
		showLoaderOnConfirm: false,
		reverseButtons: false,
		customClass: {
			confirmButton: 'btn btn-app-blue2 sm',
			cancelButton: 'btn-link sm absolute-position',
		},
		buttonsStyling: false,
		cancelButtonText: 'Cancel',
		confirmButtonText: 'Yes, delete it',
		preConfirm: () => {
			return dispatch('removeProject', {projectId});
		},
	}).then(r => {
		if (r && (r.dismiss === 'cancel' || r.dismiss === 'overlay' || r.dismiss === 'backdrop')) {
			return false;
		}

		return r.value;
	}).catch(err => {
		console.log('err', err);
		if (err === 'cancel') return false;
	});
}

export function addSegmentDialog({ dispatch }, { projectId, isFolder, fetch, data = {} }) {
	const imagePath = require('../../assets/illustrations/typing.svg');
	const title = isFolder
		? 'Add a folder'
		: 'Name your item';

	// const inputPlaceholder = isFolder
	// 	? 'Please name your folder'
	// 	: 'Please name your segment';

	return $swal.fire({
		title,
		position: 'center',
		html: `
			<form class="create-new-project" onsubmit="return false">
				<div class="form-group">
					<label>
						Enter your item’s name
					</label>
					<input id="segmentName" type="text" name="segmentName" placeholder="Start typing here..." autocomplete="disabled" class="form-control app-swal-input" />
					<div id="segmentNameFeedback" class="swal2-validation-message">Please name your section</div>
				</div>
				<img class="illustration" src="${imagePath}"/>
			</form>
		`,
		showCancelButton: true,
		showCloseButton: false,
		showLoaderOnConfirm: false,
		reverseButtons: true,
		customClass: {
			confirmButton: 'btn btn-app-blue2 sm',
			cancelButton: 'btn-link sm absolute-position',
			header: 'right',
		},
		buttonsStyling: false,
		confirmButtonText: data && data.group && data.group === 'content' ? 'Add to Project Output' : 'Add to Project Notes',
		preConfirm: () => {
			const name = document.getElementById('segmentName').value;
			if (!name || name === '') {
				// return $swal.swal.showValidationMessage(isFolder
				// 	? 'Please name your folder'
				// 	: 'Please name your segment');
				document.getElementById('segmentNameFeedback').style.display = 'block';
				return false;
			}
			document.getElementById('segmentNameFeedback').style.display = 'none';
			return dispatch('addSegment', {
				projectId,
				fetch,
				data: {
					name,
					isFolder,
					...data
				},
			});
		},
		onOpen: () => {
			document.getElementById('segmentName').focus();
		}
	}).then(r => {
		if (r && (r.dismiss === 'cancel' || r.dismiss === 'overlay' || r.dismiss === 'backdrop')) {
			return false;
		}

		return r.value;
	}).catch(err => {
		if (err === 'cancel') return false;
	});
}

export function removeSegmentDialog({ dispatch, commit }, params = {}) {
	const imagePath = require('../../assets/illustrations/paper-shredder.png');
	const {
		projectId,
		segmentId,
		segmentName,
		// isFolder,
		// contents
	} = params;

	// let displayText = `Are you sure you want to delete <b>${segmentName}<b>?`;

	// if (isFolder && contents && contents.length) {
	// 	const contentsList = contents
	// 		.map(v => typeof v === 'string' ? v : v.name)
	// 		.join('<br>- ');

	// 	const contentsText = `<br>You will also delete its contents:<br><b>- ${contentsList}</b><br><br>`;

	// 	displayText = [
	// 		`<small>You are attempting to delete <b>${segmentName}</b>. ${contentsText}All deletions are permanent.</small>`
	// 	].join('<br><br>');
	// }

	return $swal.fire({
		title: 'Delete this item?',
		html: `
			<div class="delete-project-content">
				<img class="illustration" src="${imagePath}"/>
				<div class="message-content">
					<p>
						Are you sure you want to delete <span class="bold">${segmentName}</span>?
					</p>
					<p>
						This is permanent, and the item cannot be recovered.
					</p>
				</div>
			</div>
		`,
		showCancelButton: true,
		showLoaderOnConfirm: false,
		reverseButtons: false,
		customClass: {
			confirmButton: 'btn btn-app-blue2 sm',
			cancelButton: 'btn-link sm absolute-position',
		},
		buttonsStyling: false,
		cancelButtonText: 'Cancel',
		confirmButtonText: 'Yes, delete it',
		preConfirm: () => dispatch('removeSegment', { projectId, segmentId })
			.catch(err => $swal.error(err.message))
	}).then(r => {
		if (r && (r.dismiss === 'cancel' || r.dismiss === 'overlay' || r.dismiss === 'backdrop')) {
			return Promise.resolve(false);
		} else {
			commit('REMOVE_SEGMENT', { projectId, segmentId });
			commit('REMOVE_CURSOR_DATA', { projectId, entityId: segmentId});
			dispatch('updateLayoutPaneStateAfterRemoving', { projectId, entityId: segmentId });
		}
	}).catch(err => {
		if (err === 'cancel') return;
	});
}


export function removeResourceDialog({ dispatch, commit }, params = {}) {
	const imagePath = require('../../assets/illustrations/paper-shredder.png');
	const {
		projectId,
		resourceId,
		resourceName,
		// isFolder,
		// contents
	} = params;

	// let displayText = `If you delete <b>${resourceName}</b>. All deletions are permanent.`;

	// if (isFolder && contents && contents.length) {
	// 	const contentsList = contents
	// 		.map(v => typeof v === 'string' ? v : v.name)
	// 		.join('<br>- ');

	// 	const contentsText = `<br>You will also delete its contents:<br><b>- ${contentsList}</b><br><br>`;

	// 	displayText = [
	// 		`<small>You are attempting to delete <b>${resourceName}</b>. ${contentsText}All deletions are permanent.</small>`
	// 	].join('<br><br>');
	// }

	return $swal.fire({
		title: 'Delete this item?',
		html: `
			<div class="delete-project-content">
				<img class="illustration" src="${imagePath}"/>
				<div class="message-content">
					<p>
						Are you sure you want to delete <span class="bold">${resourceName}</span>?
					</p>
					<p>
						This is permanent, and the item cannot be recovered.
					</p>
				</div>
			</div>
		`,
		showCancelButton: true,
		showLoaderOnConfirm: false,
		reverseButtons: false,
		customClass: {
			confirmButton: 'btn btn-app-blue2 sm',
			cancelButton: 'btn-link sm absolute-position',
		},
		buttonsStyling: false,
		cancelButtonText: 'Cancel',
		confirmButtonText: 'Yes, delete it',
		preConfirm: () => dispatch('removeResource', { projectId, resourceId })
			.catch(err => $swal.error(err.message))
	}).then(r => {
		if (r && (r.dismiss === 'cancel' || r.dismiss === 'overlay' || r.dismiss === 'backdrop')) {
			return Promise.resolve(false);
		} else {
			commit('REMOVE_RESOURCE', { projectId, resourceId });
			commit('REMOVE_CURSOR_DATA', { projectId, entityId: resourceId});
			dispatch('updateLayoutPaneStateAfterRemoving', { projectId, entityId: resourceId });
		}
	}).catch(err => {
		if (err === 'cancel') return;
	});
}

export function updatedEntity({commit}, entityId) {
	commit('UPDATED_ENTITY', entityId);
}

export function setSegmentContent({commit}, payload = {}) {
	commit('SET_SEGMENT_CONTENT', payload);
}

export function uploadImage({commit}, payload = {}) {
	const formData = new FormData();
	formData.append('file', payload.data.file);
	formData.append('type', payload.data.type);
	formData.append('fileName', payload.data.fileName);

	return $http.post('v1/common/uploadImage', formData);
}

export function setProseMirrorItemCursorPosition({commit}, payload) {
	commit('SET_PROSEMIRROR_ITEM_CURSOR_POSITION', payload);
}

export function generateOutput({ commit }, {projectId, outputType}) {
	const apiUrl = `v1/projects/${projectId}/output`;
	return $http.post(apiUrl, {
		type: outputType
	});
}

export function getOutputUrl({commit}, {projectId, outputId}) {
	return $http.get(`v1/common/output/${projectId}/${outputId}`);
}

export function projectUpdated({commit}, {project}) {
	commit('PROJECT_UPDATED', project);
}
