import store from "@/store";

const _lib = require("mixpanel-browser");
let _user;
let _initialized = false;

const mixPanel = {};
mixPanel.init = (apiKey) => {
	const shouldDisableEvents = window.location.hostname === "localhost";
	if (!apiKey || shouldDisableEvents) {
		return;
	}

	_lib.init(apiKey);
	_initialized = true;
	mixPanel.updateUser();
};

mixPanel.updateUser = () => {
	if (!_initialized) {
		return;
	}
	_user = getUserInfo();
	if (!_user) {
		return;
	}

	_lib.identify(_user.email);
	if (_lib.people) {
		_lib.people.set({
			id: _user.id,
			$email: _user.email,
		});
	}
};

mixPanel.identifyByEmail = (email) => {
	if (!_initialized) {
		return;
	}

	_lib.identify(email);
	if (_lib.people) {
		_lib.people.set({
			$email: email,
		});
	}
};

mixPanel.trackEvent = (eventName, view, subView) => {
	if (!_initialized) {
		return;
	}

	const data = {
		view,
	};

	if (subView) {
		data.subView = subView;
	}

	_lib.track(eventName, data);
};

function getUserInfo() {
	return store.getters["user/account"];
}

export default mixPanel;
