<template lang="pug">
	.simple-dialog-box(v-on="$listeners")
		slot
</template>

<script>
export default {
	name: 'SimpleDialogBox'
};
</script>

<style lang="scss">
	.simple-dialog-box {
		padding: 2rem;
		max-width: 425px;
		text-align: center;
		box-sizing: border-box;
		border: 2px dashed rgba(53, 59, 84, 0.15);
		border-radius: 5px;
		margin: auto;
		font-family: $proxima-semi;

		&.no-border {
			border: none !important;
		}

		&.center {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			padding: 0;
			margin: 0;
		}

		img {
			display: inline-block;
			padding-bottom: 2rem;
			max-height: 128px;
		}
		span {
			display: block;
			color: #353B54;
			font-size: 24px;
			letter-spacing: 1.5px;
			line-height: 36px;
		}

		@each $color, $value in $theme-colors {
			&.#{$color} {
				border: 2px dashed rgba($value, 0.25);
				span { color: $value; }
			}
		}
	}
</style>
