import _set from 'lodash/set';

export function SET(state, [path, value]) {
	_set(state, path, value);
}

export function SET_PAGE_LOADER_STATE(state, value) {
	state.pageLoader = !!value;
}

export function SET_CLOSE_WARNING(state, value) {
	state.closeWarning = value;
}

export function RELEASE_CLOSE_WARNING(state, value) {
	if (state.closeWarning !== value) {
		return;
	}

	state.closeWarning = null;
}

export function SET_LOADING_OVERLAY_STATE(state, value) {
	state.loadingOverlay = value;
}

export function RESET_LOADING_OVERLAY_STATE(state) {
	state.loadingOverlay = {active: false, message: ''};
}

export function SET_WS_CONNECTION_STATE(state, value) {
	state.wsState = value;
}

export function SET_AUTH_EXECUTED(state, executed) {
	state.authenticationExecuted = executed;
}

export function SET_API_ERROR_MODAL_CONFIG(state, value) {
	state.apiErrorModalConfig = value;
}

export function RESET_API_ERROR_MODAL_CONFIG(state) {
	state.apiErrorModalConfig = {active: false, title: '', text: '', buttonText: '', redirectRoute: ''};
}

export function SET_INLINE_NOTIFICATION_MODAL_CONFIG(state, value) {
	state.inlineNotificationModalConfig = value;
}

export function RESET_INLINE_NOTIFICATION_MODAL_CONFIG(state) {
	state.inlineNotificationModalConfig = {duration: 0, type: 'success', message: ''};
}
