<template lang="pug">
	b-modal#members-list-modal(size="members-list", centered, :no-close-on-backdrop="false", :hide-footer="!isOwner()", @hidden="reset")
		transition(name="slide-fade")
		template(slot="modal-header")
			h2.modal-title {{modalTitle}}
			button(:class="'close'", :type="'button'" @click="close") ×
		.members-container(v-if="projectMembers.length")
			div.member(v-for="member in projectMembers")
				.image-container
					img.owner(v-if="memberHasOwnerRoleForProject(member)", src="@/assets/common/owner-icon.svg")
				.member-data
					template(v-if="!isMembershipPending(member)")
						p.member-name {{member.name}} {{isYourself(member) ? '(you)' : ''}}
						p.member-email {{member.email}}
					template(v-else)
						p.member-name.pending Invite pending
						p.member-email.pending {{member.email}}
				.member-action-menu
					members-list-modal-action-menu(
						:project="project", :user="member",
						:canRemoveOwner="canRemoveOwner",
						@fetch-members="fetchMembers",
						@show-message="showMessage($event)",
						@close-modal="close"
						:source="source"
					)
		template(slot="modal-footer")
			h2.footer-title {{footerTitle}}
			.add-collaborator
				form(@submit.prevent="addCollaborator")
					//-label.collaborator-label Invite people to collaborate
					input.collaborator(
						type="text",
						name="collaborator",
						placeholder="Enter email address to invite",
						autocomplete="off",
						v-model="collaboratorEmail",
						v-validate="'required|email'",
					)
					a.btn.add-collaborator-btn(@click="addCollaborator")
						img.collaborator-img(src="@/assets/common/send-invitation.svg")
						span Send invitation
</template>

<script>
	import formMixin from '@/mixins/form';
	import { mapGetters } from 'vuex';
	import MembersListModalActionMenu from "./MembersListModalActionMenu";

	export default {
		name: 'MembersListModal',
		components: {MembersListModalActionMenu},
		mixins: [formMixin],
		props: {
			source: String,
			project: {
				type: Object,
				required: true,
			}
		},
		watch: {
			project(newValue) {
				this.footerTitle = 'Invite teammates to ' + this.project.name;
				this.modalTitle = `Team roster for ${this.project.name}`;
				this.fetchMembers();
			},
		},
		data: () => ({
			currentUser: {},
			modalTitle: '',
			footerTitle: '',
			projectMembers: [],
			collaboratorEmail: '',
			inProgress: false,
			customErrorMessage: '',
			addMemberParams: {
				projectId: '',
				email: '',
				role: 'member'
			},
			canRemoveOwner: false,
			customFormErrorMessages: {
				custom: {
					collaborator: {
						required: 'Email field is required.',
						email: 'Please enter a valid email address.',
					}
				}
			}
		}),
		computed: {
			...mapGetters({account: 'user/account'}),
		},
		methods: {
			close() {
				this.$mixPanel.trackEvent('Close Project Members', this.source, 'Project Members');
				this.$root.$emit('bv::hide::modal', 'members-list-modal');
			},
			reset() {
				this.collaboratorEmail = '';
			},
			addCollaborator() {
				if (this.inProgress) {
					return;
				}
				this.customErrorMessage = '';
				this.inProgress = true;
				this.formValidate().then(valid => {
					if (!valid) {
						this.showMessage({duration: 5, type: 'danger', message: this.errors.first('collaborator')});
						this.inProgress = false;
						return;
					}
					const alreadyMember = this.projectMembers.filter(member => member.email === this.collaboratorEmail);
					if (alreadyMember.length) {
						this.showMessage({duration: 5, type: 'danger', message: 'The person you invited is already a teammate on this project.'});
						this.inProgress = false;
						return;
					}
					this.addMemberParams.email = this.collaboratorEmail;
					this.addMemberParams.projectId = this.project._id;
					this.$store.dispatch('projects/addUser', this.addMemberParams)
						.then(response => {
							if (response.success) {
								this.$mixPanel.trackEvent('Users Added', 'NA', 'NA');
								const users = response.users;
								const invitedUser = users.find(user => user.email === this.collaboratorEmail);
								let message;
								if (invitedUser?.name) {
									message = `We've just invited ${invitedUser?.name} to this project for you.`;
								} else {
									message = `You've invited ${this.collaboratorEmail} to this project. We’ll email you when they join it.`;
								}
							this.showMessage({duration: 5, type: 'success', message});
								this.projectMembers = users;
								this.canRemoveOwner = this.hasManyOwners();
								this.inProgress = false;
								this.collaboratorEmail = '';
							}
						})
						.catch(err => {
							this.$mixPanel.trackEvent('Add Project Member, Failed', this.source, 'Project Members');
							this.inProgress = false;
						});
				});
			},
			inviteCollaborator() {
				this.$store.dispatch('projects/inviteUser', {projectId: this.project._id ,email: this.collaboratorEmail})
					.then(response => {
						this.$mixPanel.trackEvent('Users Added', 'NA', 'NA');
						this.showMessage({duration: 5, type: 'success', message: `Invitation successfully sent to ${this.collaboratorEmail}.`});
						this.reset();
					})
					.catch(err => this.$swal.error('Error', err.message));
			},
			fetchMembers() {
				this.$store.dispatch('projects/getProjectUsers', {projectId: this.project._id})
					.then(response => {
						this.projectMembers = response;
						this.canRemoveOwner = this.hasManyOwners();
					})
					.catch(err => this.$swal.error('Error', err.message));
			},
			isYourself(member) {
				return this.currentUser.id === member.id;
			},
			isOwner() {
				return this.project && this.project.userRole === 'owner';
			},
			memberHasOwnerRoleForProject(member) {
				return member.role === 'owner';
			},
			showMessage(data) {
				this.$store.commit('SET_INLINE_NOTIFICATION_MODAL_CONFIG', data);
			},
			hasManyOwners() {
				const owners = this.projectMembers.filter(member => member.role === 'owner');
				return owners.length > 1;
			},
			isMembershipPending(member) {
				return member.status === 'invitePending';
			},
		},
		created() {
			this.currentUser = this.account;
			this.$validator.localize('en', this.customFormErrorMessages);
		},
	};
</script>

<style lang="scss">
	#members-list-modal {
		font-family: 'Proxima Nova Medium', sans-serif;
		padding-bottom: 20px;
	}
	#members-list-modal___BV_modal_body_ {
		padding-left: 0;
		padding-right: 0;
	}
	#members-list-modal___BV_modal_header_ {
		padding-top: 3rem;
		padding-left: 3.5rem;
		.close {
			display: block;
			font-size: 40px;
			padding: 0;
			position: absolute;
			top: 20px;
			right: 30px;
			font-weight: normal;
			color: #273F52;
			opacity: 0.4;
		}
	}
	#members-list-modal___BV_modal_content_{
		border: none;
		width: 640px;
		min-height: 480px;
		.members-container {
			max-height: 325px;
			overflow-y: auto;
			.member {
				display: flex;
				width: 100%;
				.image-container {
					width: 4rem;
					text-align: center;
					padding-top: 8px;
				}
				.member-data {
					width: 83%;
					.owner {
						position: absolute;
						left: 30px;
						top: 30px;
					}
					.member-name {
						margin-bottom: 0;
						font-size: 13px;
						color: #3E98F3;
						font-weight: 600;
						&.pending {
							color: #DC9D4C;
						}
					}
					.member-email {
						font-size: 16px;
						color: #172937;
						font-weight: 500;
						&.pending {
							color: #B6CBDC;
						}
					}
				}
				.member-action-menu {
					width: 2%;
					padding-top: 10px;
				}
			}
		}
	}
	#members-list-modal___BV_modal_backdrop_ {
		background: #273F52;
		opacity: 0.9;
	}
	.modal-footer > * {
		margin: 0;
	}
	#members-list-modal___BV_modal_footer_ {
		background-color: #3E98F3;
		color: #FFFFFF;
		border-radius: 0 0 5px 5px;
		padding: 45px 45px 70px 3.5rem;
		justify-content: flex-start;
		.footer-title {
			margin-bottom: 46px;
		}
		.add-collaborator {
			width: 100%;
			padding: 0;
			input {
				width: 250px;
				height: 35px;
				font-size: 16px;
				background-color: transparent;
				border: none;
				border-bottom: 1px solid #DAD4DF;
				color: #FFFFFF;
				&::placeholder {
					color: #FFFFFF;
				}
			}
			form {
				display: grid;
				grid-template-columns: 1fr 1fr;
			}
			.collaborator-label {
				color: #1F344A;
				font-size: 13px;
				display: block;
				font-weight: 600;
				margin-bottom: 0;
			}
			.add-collaborator-btn {
				border: 1.5px solid #FFFFFF;
				border-radius: 20px;
				width: 160px;
				height: 35px;
				font-size: 15px;
				justify-self: end;
				margin-right: 20px;
				span {
					color: #FFFFFF;
				}
				.collaborator-img {
					margin: 0;
					width: 20px;
					position: relative;
					top: -4px;
					left: -4px;
				}
			}
		}
		.invite {
			display: inline-block;
			cursor: pointer;
			.add-member-img {
				display: inline-block;
				width: 20px;
				height: 16px;
				margin-top: 0;
				position: relative;
				top: -4px;
			}
			a {
				color: #FFFFFF;
				font-size: 16px;
				padding-left: 8px;
			}
		}
	}
</style>
