<template lang="pug">
	.template(v-if="editor")
		.menubar
			menu-bar.editor__menu(:editor="editor", :projectId="projectId")
		.editor(v-if="editor", :class="pmContainerClass")
			editor-content.editor__content(:editor="editor", @scroll.native="onScroll")
		.status-bar(v-if="isPaneActive")
			.word-counter.counter-style(:style="{width: statusCounters.design.wordCounterWidth + 'px'}") {{ statusCounters.words }}
			.word-counter-label {{+statusCounters.wordsCounter > 1 || +statusCounters.wordsCounter === 0 ? 'words' : 'word'}}
			.status-separator ꞏ
			.character-counter.counter-style(:style="{width: statusCounters.design.charCounterWidth + 'px'}") {{ statusCounters.characters }}
			.character-counter-label characters
			.status-separator ꞏ
			.reading-time-counter.counter-style(:style="{width: statusCounters.design.readingTimeWidth + 'px'}") {{ statusCounters.readingTime }}
			.reading-time-counter-label reading time

</template>

<script>
import store from "@/store";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import * as Y from "yjs";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { IndexeddbPersistence } from "y-indexeddb";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import CharacterCount from "@tiptap/extension-character-count";
import MenuBar from "./MenuBar.vue";

export default {
	name: "ProseMirrorEditorV2",
	components: {
		EditorContent,
		MenuBar,
	},
	props: {
		namespace: { type: String, required: true },
		projectId: { type: String },
		entityId: { type: String },
		paneIdx: { type: Number },
	},
	data: () => ({
		currentUser: {
			name: null,
			color: null,
		},
		provider: null,
		indexDb: null,
		editor: null,
		yDoc: null,
		pmContainerClass: "single-view",
		url: process.env.VUE_APP_COLLAB_ENDPOINT,
		wordsPerMinute: 225,
		itemLoadedInPane: false,
		persistenceReady: false,
		hasConnected: false,
		hasSynced: false,
	}),
	computed: {
		displayUsers() {
			const result = [];
			this.editor.storage.collaborationCursor.users.forEach((user) => {
				if (
					result.findIndex(
						(x) => x.name === user.name && x.color === user.color
					) === -1
				) {
					result.push(user);
				}
			});
			return result;
		},
		isPaneActive() {
			return this.$store.getters["projects/getPaneActiveStatus"](
				this.projectId,
				this.paneIdx
			);
		},
		cursorPosition() {
			return this.$store.getters["projects/getCursorPosition"](
				this.projectId,
				this.entityId,
				this.paneIdx
			);
		},
		paneLayoutViewMode() {
			return this.$store.getters["projects/getViewMode"](this.projectId);
		},
		statusCounters() {
			const result = {
				characters: "",
				words: "",
				readingTime: 0,
				design: {
					wordCounterWidth: 0,
					charCounterWidth: 0,
					readingTimeWidth: 0,
				},
			};

			if (!this.isPaneActive) {
				return result;
			}

			const text = this.editor.getText();
			const matches = text.match(/[\w\d\\'\\’\\`-]+/gi);
			const wordCount = matches ? matches.length : 0;

			result.words = wordCount;
			result.characters = this.editor.storage.characterCount.characters();
			const minutes = Math.ceil(wordCount / this.wordsPerMinute);
			result.readingTime = `${minutes} minute${minutes < 2 ? "" : "s"}`;
			if (result.words < 10) {
				result.design.wordCounterWidth = 18;
			} else if (result.words < 100) {
				result.design.wordCounterWidth = 28;
			} else if (result.words < 1000) {
				result.design.wordCounterWidth = 38;
			} else if (result.words < 10000) {
				result.design.wordCounterWidth = 48;
			} else {
				result.design.wordCounterWidth = 60;
			}

			if (result.characters < 10) {
				result.design.charCounterWidth = 20;
			} else if (result.characters < 100) {
				result.design.charCounterWidth = 30;
			} else if (result.characters < 1000) {
				result.design.charCounterWidth = 38;
			} else if (result.characters < 10000) {
				result.design.charCounterWidth = 50;
			} else if (result.characters < 100000) {
				result.design.charCounterWidth = 60;
			} else {
				result.design.charCounterWidth = 70;
			}

			if (minutes < 2) {
				result.design.readingTimeWidth = 75;
			} else if (minutes < 10) {
				result.design.readingTimeWidth = 80;
			} else if (minutes < 100) {
				result.design.readingTimeWidth = 90;
			} else if (minutes < 1000) {
				result.design.readingTimeWidth = 100;
			} else {
				result.design.readingTimeWidth = 110;
			}

			result.wordsCounter = result.words;

			const numberFormat = Intl.NumberFormat();
			result.characters = `${numberFormat.format(result.characters)}`;
			result.words = `${numberFormat.format(result.words)}`;
			return result;
		},
		everythingReady() {
			return this.persistenceReady && this.hasConnected && this.hasSynced;
		},
	},
	watch: {
		immediate: false,
		everythingReady: {
			handler: function (newState, oldState) {
				if (newState && newState.state === false) {
					this.editor.destroy();
					return;
				}

				const self = this;

				this.editor = new Editor({
					autofocus: self.isPaneActive,
					extensions: [
						// Collaboration extension has it's own history extension, so we need to omit default one
						StarterKit.configure({
							history: false,
						}),
						Underline,
						Image.configure({ inline: true }),
						Link,
						CollaborationCursor.configure({
							provider: self.provider,
							user: self.currentUser,
						}),
						Collaboration.configure({
							provider: self.provider,
							document: self.yDoc,
						}),
						Placeholder.configure({
							placeholder: this.getRandomPlaceholderMessage(),
							emptyEditorClass: "is-editor-empty",
						}),
						CharacterCount.configure({
							limit: 0,
						}),
					],
					editorProps: {
						attributes: {
							spellcheck: true,
						},
					},
				});

				setTimeout(() => {
					this.$root.$emit("segment-item-loaded", {
						id: this.entityId,
						paneIdx: this.paneIdx,
					});

					self.restoreCursorPosition();
				}, 1);

				localStorage.setItem("currentUser", JSON.stringify(this.currentUser));
			},
		},
	},
	created() {
		this.$root.$on("layout-changed", this.setPMContainerClass);
		this.createEditorInLoadedPane(this.namespace);
	},
	beforeDestroy() {
		this.$root.$off("layout-changed", this.setPMContainerClass);
		this.editor?.destroy();
		this.provider?.destroy();
	},
	methods: {
		onScroll(e) {
			this.updateCursorPosition({
				scrollTop: e.srcElement.scrollTop,
			});
		},
		setPMContainerClass() {
			this.pmContainerClass =
				this.paneLayoutViewMode > 1 ? "double-view" : "single-view";
		},
		restoreCursorPosition() {
			setTimeout(() => {
				const positionToRestore = this.cursorPosition;
				if (this.editor && this.editor.contentComponent) {
					if (positionToRestore) {
						// this.editor.commands.setTextSelection({from: positionToRestore.from, to: positionToRestore.to});
						this.editor.contentComponent.$el.scrollTop =
							positionToRestore.scrollTop;
					} else {
						this.editor.contentComponent.$el.scrollTop = 0;
					}
				}
			}, 1);
		},
		async checkItem() {
			return this.$store.dispatch("projects/checkItem", {
				projectId: this.projectId,
				resourceId: this.entityId,
			});
		},
		async isItemInIndexedDb(namespace) {
			return new Promise((resolve, reject) => {
				const indexedDB =
					window.indexedDB ||
					window.mozIndexedDB ||
					window.webkitIndexedDB ||
					window.msIndexedDB;

				if (!indexedDB) {
					console.log(
						`Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.`
					);
					resolve(false);
					return;
				}

				if (indexedDB.databases) {
					// Chrome and Safari
					indexedDB.databases().then((x) => {
						const allDbs = x.map((db) => db.name);
						const isExisting = allDbs.includes(namespace);
						resolve(isExisting);
					});
				} else {
					// Firefox support
					const request = indexedDB.open(namespace);
					request.onupgradeneeded = function (e) {
						if (request.result.version === 1) {
							indexedDB.deleteDatabase(namespace);
							resolve(false);
						}
					};

					request.onsuccess = function (e) {
						resolve(true);
					};
				}
			});
		},
		async createEditorInLoadedPane(namespace) {
			const user = store.state.user;
			if (!user) {
				throw new Error("User not authenticated");
			}
			this.setPMContainerClass();

			const hasIndexedDbSet = await this.isItemInIndexedDb(namespace);
			const currentUserInfo = localStorage.getItem("currentUser");
			if (currentUserInfo) {
				const currentUser = JSON.parse(currentUserInfo);
				this.currentUser.color = currentUser.color;
			} else {
				this.currentUser.color = this.getRandomColor();
			}

			const userInfo = user.account.user ? user.account.user : user.account;
			this.currentUser.name = userInfo.fullName;

			this.yDoc = new Y.Doc();

			this.yDoc.on("update", (update) => {
				// When the READY key is true, it means our data has been loaded from the db
				const metaDataMap = this.yDoc.getMap("persistenceMetadata");
				if (metaDataMap) {
					this.persistenceReady = metaDataMap.get("READY");
				}
			});

			this.indexDb = new IndexeddbPersistence(namespace, this.yDoc);

			const token = user.token;
			this.provider = new HocuspocusProvider({
				url: this.url,
				name: namespace,
				document: this.yDoc,
				parameters: {
					token,
					hasIndexedDbSet: hasIndexedDbSet,
				},
			});

			const self = this;
			const currentItemId = this.entityId;

			this.provider.on("status", async (event) => {
				if (event.status === "connected" && !this.itemLoadedInPane) {
					this.hasConnected = true;
					this.itemLoadedInPane = true;
					const item = await this.checkItem();
					if (currentItemId === self.entityId && item.isWebClipperContent) {
						self.editor.commands.setContent(item.clippedContent);
						self.updateCursorPosition({
							scrollTop: 1,
							from: 0,
							to: 0,
						});
					}

					// self.restoreCursorPosition();
				}
			});

			this.provider.on("sync", async (synced) => {
				this.hasSynced = synced;
				if (!synced) {
					return;
				}

				// this.restoreCursorPosition();
			});
		},
		updateCursorPosition(currentPosition) {
			if (currentPosition.scrollTop === 0) {
				return;
			}

			if (this.timeout) {
				clearTimeout(this.timeout);
			}

			this.timeout = setTimeout(() => {
				this.$store?.dispatch("projects/setProseMirrorItemCursorPosition", {
					projectId: this.projectId,
					entityId: this.entityId,
					paneIdx: this.paneIdx,
					currentPosition,
				});
			}, 1000);
		},
		updateCurrentUser(attributes) {
			this.currentUser = { ...this.currentUser, ...attributes };
			this.editor.chain().focus().user(this.currentUser).run();
			localStorage.setItem("currentUser", JSON.stringify(this.currentUser));
		},
		getRandomColor() {
			return this.getRandomElement([
				"#212529",
				"#E67700",
				"#D9480F",
				"#C92A2A",
				"#A61E4D",
				"#862E9C",
				"#5F3DC4",
				"#364FC7",
				"#087F5B",
				"#2B8A3E",
				"#5C940D",
			]);
		},
		getRandomElement(list) {
			return list[Math.floor(Math.random() * list.length)];
		},
		getUserInitials(name) {
			return name.match(/\b(\w)/g).join("");
		},
		getRandomPlaceholderMessage() {
			return this.getRandomElement([
				"Start typing here...",
				"Write your masterpiece here...",
				"Knock their socks off here...",
				"You can do this! Start here...",
				"Be brave! Start here...",
				"Writers change the world! Start here...",
				"Your words matter! Start here...",
				"Your ideas are good! Start here...",
				"Words change the world! Start here...",
				"Just one word at a time. Start here...",
			]);
		},
	},
};
</script>

<style lang="scss">
@import "@/scss/prose-mirror/pm";
.editor {
	display: flex;
	flex-direction: column;
	height: 98%;

	&__header {
		display: flex;
		flex: 0 0 auto;
		flex-wrap: wrap;
		padding: 0.25rem;
		border-bottom: 3px solid #0d0d0d;
	}

	&__content {
		// padding: 1.25rem 0rem;
		flex: 1 1 auto;
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	&__footer {
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		white-space: nowrap;
		border-top: 3px solid #0d0d0d;
		font-size: 12px;
		font-weight: 600;
		color: #0d0d0d;
		padding: 0.25rem 0.75rem;
	}

	/* Some information about the status */
	&__status {
		display: flex;
		align-items: center;
		border-radius: 5px;

		&::before {
			content: " ";
			flex: 0 0 auto;
			display: inline-block;
			width: 0.5rem;
			height: 0.5rem;
			background: rgba(#0d0d0d, 0.5);
			border-radius: 50%;
			margin-right: 0.5rem;
		}

		&--connecting::before {
			background: #616161;
		}

		&--connected::before {
			background: #b9f18d;
		}
	}

	&__name {
		button {
			background: none;
			border: none;
			font: inherit;
			font-size: 12px;
			font-weight: 600;
			color: #0d0d0d;
			border-radius: 0.4rem;
			padding: 0.25rem 0.5rem;

			&:hover {
				color: #fff;
				background-color: #0d0d0d;
			}
		}
	}
}
/* Give a remote user a caret */
.collaboration-cursor__caret {
	position: relative;
	margin-left: -1px;
	margin-right: -1px;
	border-left: 1px solid #0d0d0d;
	border-right: 1px solid #0d0d0d;
	word-break: normal;
	pointer-events: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
	color: #ffffff !important;
	position: absolute;
	top: -1.4em;
	left: -1px;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	user-select: none;
	color: #0d0d0d;
	padding: 0.1rem 0.3rem;
	border-radius: 3px 3px 3px 0;
	white-space: nowrap;
}

/* Basic editor styles */
.single-view .ProseMirror {
	width: 45%;
	@media screen and (max-width: 1366px) {
		width: 60%;
	}
	@media screen and (max-width: 1080px) {
		width: 80%;
	}
}
.double-view .ProseMirror {
	width: 75%;
	@media screen and (max-width: 1366px) {
		width: 80%;
	}
	@media screen and (max-width: 1080px) {
		width: 90%;
	}
}
.ProseMirror {
	margin-left: auto;
	margin-right: auto;
	margin-top: 1rem;
	> * + * {
		margin-top: 0.75em;
	}
	a {
		color: #3e82df;
		&:hover {
			color: #3e82df;
		}
	}
	ul,
	ol {
		padding: 0 1rem;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: #172937;
		font-family: "Metropolis Extra Bold", sans-serif;
	}
	h1 {
		font-size: 50px;
		line-height: 60px;
	}
	h2 {
		font-size: 40px;
		line-height: 48px;
	}
	h3 {
		font-size: 30px;
		line-height: 36px;
	}
	p {
		color: #172937;
		font-family: "Metropolis Medium", sans-serif;
		font-size: 15px;
		line-height: 24px;
	}
	code {
		background-color: rgba(#616161, 0.1);
		color: #616161;
	}
	pre {
		background: #0d0d0d;
		color: #fff;
		font-family: "JetBrainsMono", monospace;
		padding: 0.75rem 1rem;
		border-radius: 0.5rem;
		code {
			color: inherit;
			background: none;
			font-size: 0.8rem;
		}
	}
	mark {
		background-color: #faf594;
	}
	img {
		max-width: 100%;
		height: auto;
	}
	hr {
		margin: 1rem 0;
	}
	em {
		font-family: "Metropolis Regular", sans-serif;
	}
	blockquote {
		padding-left: 16px;
		border-left: 2px solid rgba(#0d0d0d, 0.1);
		padding-right: 24px;
	}
	ul {
		ul {
			margin-left: 10px;
			ul {
				list-style: none;
				padding-left: 0;
				li {
					padding-left: 12px;
					&:before {
						content: "-";
						position: absolute;
						margin-left: -15px;
					}
				}
			}
		}
	}
	ol {
		padding-left: 1rem;
		ol {
			list-style-type: lower-latin;
			margin-left: 15px;
			ol {
				list-style-type: lower-roman;
				margin-left: 5px;
			}
		}
	}
	hr {
		border: none;
		border-top: 2px solid rgba(#0d0d0d, 0.1);
		margin: 2rem 0;
	}
	ul[data-type="taskList"] {
		list-style: none;
		padding: 0;
		li {
			display: flex;
			align-items: center;
			> input {
				flex: 0 0 auto;
				margin-right: 0.5rem;
			}
		}
	}
	strong,
	em {
		font-family: "Metropolis Bold", sans-serif;
		line-height: 23px;
	}
}
</style>
