<template lang="pug">
	.segment-menu-item(v-on="$listeners")
		slot
			.segment-item-title
				slot(name="title")

			.segment-item-actions
				slot(name="actions")
</template>

<script>
export default {
	name: 'SegmentMenuItem',
	data: () => ({
	}),
	computed: {
	}
};
</script>

<style lang="scss">
	.segment-menu-item {
		width: 100%;
		font-family: $proxima-medium;
		font-size: 13px;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.01px;
		color: #222222;
	}
</style>
