<template lang="pug">
	.wrapper
		.info-area
			img.illustration(:src="writerImage", v-bind:class="{mirror: mirror}")
			ladda.btn.btn-app-blue2.no-shadow.sm(
				:loading="isSubmit",
				@click="openMenu",
				data-style="zoom-out"
			) Add an idea
			div.action-menu-holder(:class="{ visible:showMenu }")
				.action-menu
					ul
						li
							a(@click="writeContent") Write some content
						li.content-end
							a(@click="addContent") Upload content
						li
							a(@click="writeNotes") Take some notes
						li.notes-end
							a(@click="addResource") Upload notes
			//-.let-us-know Need some help?
			//-.links
				a.cursor(@click.stop="toggleSupportMenu") Support & Feedback
					img(v-if="supportMenuOpen",src="@/assets/panel/toolbar/arrow-up.svg")
					img(v-else,src="@/assets/panel/toolbar/arrow-down.svg")
					ul.support-menu(:class="[supportMenuOpen? 'show' : 'hide']")
						li
							a(href="https://www.surveymonkey.com/r/2F9V32L", target="_blank") Survey 1
						li
							a(href="https://www.surveymonkey.com/r/VMS58N6", target="_blank") Survey 2
						li
							a(@click="openFeedback") Feedback
							feedback-modal
						li
							a(href="https://slice.tech/", target="_blank") Website
</template>

<script>

import FeedbackModal from './FeedbackModal';
import { trackEvent } from '../../lib/helphero';
import randomImageMixin from '../../mixins/random-image';

export default {
	name: '',
	mixins: [randomImageMixin],
	components: {
		'FeedbackModal': FeedbackModal,
	},
	props: {
		projectId: { type: String, required: true },
		paneIdx:   { type: Number }
	},
	data: () => ({
		supportMenuOpen: false,
		isSubmit: false,
		showMenu: false
	}),
	computed: {
		activePaneIndex() {
			return this.$store.getters['projects/getActivePaneIndex'](this.projectId);
		},
		mirror() {
			return this.applyMirrorEffect();
		},
		writerImage() {
			return this.getRandomWriterImage();
		}
	},
	watch: {
	},
	methods: {
		loadItemToActivePane(item, itemEntityType) {
			const newState = {
				active: true,
				entityType: itemEntityType,
				attrs: {entityId: item._id}
			};
			this.$store.dispatch('projects/updateLayoutPane', {
				projectId: this.projectId,
				paneIdx: this.activePaneIndex,
				newState: newState
			});
		},
		openMenu(event) {
			this.showMenu = !this.showMenu;
		},
		writeContent() {
			const projectId = this.projectId;
			const data = {projectId, fetch: false, data: {name: 'Untitled item', isFolder: false, type: 'prosemirror', group: 'content'}};
			this.$store.dispatch('projects/addSegment', data).then(
				segment => this.loadItemToActivePane(segment, 'segment')
			);
			this.showMenu = false;
		},
		addContent() {
			this.$root.$emit('app:modal:project_resource_new', { projectId: this.projectId, data: { group: 'content' }});
			this.showMenu = false;
		},
		writeNotes() {
			const projectId = this.projectId;
			const data = {projectId, fetch: false, data: {name: 'Untitled item', isFolder: false, type: 'prosemirror', group: 'resource'}};
			this.$store.dispatch('projects/addResource', data).then(
				resource => this.loadItemToActivePane(resource, 'segment')
			);
			this.showMenu = false;
		},
		addResource() {
			this.$mixPanel.trackEvent('Begin Upload Notes', 'Project ', 'Project Pane');
			this.$root.$emit('app:modal:project_resource_new', { projectId: this.projectId, data: { group: 'resource' }});
			this.showMenu = false;
		},
		openFeedback() {
			trackEvent('clicked_open_feedback');
			this.$root.$emit('bv::show::modal', 'feedback_modal');
		},
		toggleSupportMenu() {
			this.supportMenuOpen = !this.supportMenuOpen;
			if (this.supportMenuOpen) {
				document.addEventListener('click', this.toggleSupportMenu);
			} else {
				document.removeEventListener('click', this.toggleSupportMenu);
			}
		},
		closePopupOnOutsideClick(event) {
			if (event.target.id !== 'ladda-menu-button' && event.target.id !=='ladda-button') {
				this.showMenu = false;
			}
		},
	},
	created() {
		document.addEventListener('click', e => this.closePopupOnOutsideClick(e));
	},
	beforeDestroy() {
		document.removeEventListener('click', e => this.closePopupOnOutsideClick(e));
	}
};
</script>

<style lang="scss">
.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: inherit;
	margin-top: 63px;

	.info-area {
		display: flex;
		flex-direction: column;
		position: relative;
		p {
			width: 334px;
			font-family: 'MuseoSlab';
			font-size: 18px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.11;
			letter-spacing: -0.02px;
			text-align: center;
			color: #172937;
		}
		img {
			width: 116px;
			height: 56px;
			margin-left: 17px;
			&.mirror {
				transform: scaleX(-1);
				margin-left: 17px;
			}
		}
		.let-us-know {
			margin-top: 50px;
			font-family: $proxima-medium;
			font-size: 13px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			text-align: center;
			color: #dad4df;
		}

		.links {
			margin-top: 10px;
			margin-left: 20px;
			width: 150px;
			border-radius: 4px;

			a {
				font-family: $proxima-medium;
				font-size: 14px;
				font-weight: 100;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: -0.04px;
				color: #3e98f3 !important;
			}

			ul {
				box-shadow: 0 20px 30px -5px rgba(0, 0, 0, 0.15);
				background-color: #ffffff;
				z-index: 2;
				padding-bottom: 20px;
				padding-inline-start: 0px;
				list-style: none;
				padding-left: 15px;
				padding-top: 8px;

				li {
					padding-top: 10px;
					padding-left: 10px;

					&:hover > a {
						color: #3e98f3 !important;
					}

					a {
						cursor: pointer;
						user-select: none;
						font-size: 15px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: normal;
						letter-spacing: -0.2px;
						color: black !important;
						&:hover {
							color: #3e98f3 !important;
							text-decoration: none;
						}
					}
				}
			}

			img {
				margin-left: 15px;
			}
		}
		.action-menu-holder {
			width: 180px;
			border-radius: 4px;
			box-shadow: 0 20px 30px -5px rgba(0, 0, 0, 0.15);
			background-color: #ffffff;
			z-index: 1500;
			padding-top: 8px;
			position: absolute;
			bottom: -150px;
			left: 50%;
			display: none;
			.action-menu {
				max-width: fit-content;
				ul {
					padding-left: 30px;
					text-align: left;
					list-style: none;
					li {
						padding-top: 10px;
						&.content-end {
							border-bottom: 1px solid #EFF2FC;
							padding-bottom: 10px;
						}
						a {
							cursor: pointer;
							font-size: 15px;
							font-weight: 500;
							color: #000;
							font-stretch: normal;
							font-style: normal;
							line-height: normal;
							letter-spacing: -0.2px;
							font-family: 'Proxima Nova Medium', sans-serif;
							&:hover {
								color: #3e98f3;
							}
						}
					}
				}
			}
		}
		.visible {
			display: block;
		}
	}
}
</style>
