import _get from 'lodash/get';
import VueSweetalert2 from 'vue-sweetalert2';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

const swal = Swal.mixin({
	customClass: {
		confirmButton: 'btn btn-app-yellow no-shadow sm mr-2 ml-2',
		cancelButton: 'btn btn-app-default no-shadow sm mr-2 ml-2',
		header: 'right',
		input: 'form-control'
	},
	buttonsStyling: false,
	showClass: {
		popup: 'animated fadeInDown faster'
	},
	hideClass: {
		popup: 'animated fadeOutUp faster'
	}
});

const lite = (title, html, icon = 'success') => swal.fire({ title, html, icon, heightAuto: false });

const api = {
	success: (title, message) => lite(title, message, 'success'),
	error:   (title, message) => lite(title, message, 'error'),
	warning: (title, message) => lite(title, message, 'warning'),
	warn:    (title, message) => lite(title, message, 'warning'),
	info:    (title, message) => lite(title, message, 'info'),

	fire(...args) {
		// fix html swal inputs confirm by enter
		setTimeout(() => {
			const inputs = document.getElementsByClassName('app-swal-input');
			const onKeyPress = (event) => {
				if (!event) event = window.event;

				const keyCode = event.keyCode || event.which;

				if (keyCode === 13) {
					swal.clickConfirm();
				}
			};

			inputs.forEach(input => {
				input.onkeypress = onKeyPress;
			});
		}, 100);

		return swal.fire(...args);
	},
	swal: swal,

	dialog: (opts = {}, cb) => {
		const i = swal.fire({
			title: opts.label || 'Are you sure?',
			html: opts.html || opts.title || '',
			icon: opts.type || opts.icon || 'warning',
			heightAuto: false,
			showCancelButton: true,
			confirmButtonText: opts.confirm || 'Yes, I\'m Sure',
			showLoaderOnConfirm: !!opts.preConfirm,
			preConfirm: opts.preConfirm,
			reverseButtons: true,
			customClass: {
				actions: 'right',
				confirmButton: 'btn btn-app-blue no-shadow sm mr-2 ml-2 pt-0',
				cancelButton: 'btn btn-app-default no-shadow sm mr-2 ml-2',
			},
			buttonsStyling: false
		});

		return new Promise((resolve, reject) => {
			i.then(r => {
				if (opts.preventCancel !== false && (r.dismiss === 'cancel' || r.dismiss === 'overlay')) return;
				if (cb) cb(r);

				return resolve(r);
			}).catch(reject);
		});
	},

	showError: function(title, err, opts) {
		opts = opts || { displayHandled: false };

		if (arguments.length === 1) {
			err = title;
			title = 'Something went wrong';
		}

		let message = '';

		if (typeof err === 'string') {
			message = err;
		} else if (err.message) {
			message = err.message;
		}

		if (!opts.displayHandled && _get(err, 'handled')) {
			return;
		}

		lite(title, message, 'error');
	},

	install: (Vue, options) => {
		// call orign install
		VueSweetalert2.install(Vue, options);

		// extended swal api interface
		Object.assign(Vue.swal, api);
	}
};

export default api;
