<template lang="pug">
	prose-mirror-editor-v2.pm-container(:key="namespace", :namespace="namespace", :projectId="projectId", :entityId="entityId", :paneIdx="paneIdx", @onUserListUpdate="onUserListUpdate")
</template>

<script>

import _debounce from 'lodash/debounce';
import projectMixin from '../../project-mixin';
import { mapGetters } from 'vuex';
import ProseMirrorEditorV2 from './ProseMirrorEditorV2';

export default {
	name: 'ProseMirrorEditorContainer',
	mixins: [projectMixin],
	components: {
		ProseMirrorEditorV2
	},
	props: {
		projectId: { type: String, required: true },
		entityId: { type: String, required: true },
		paneIdx: { type: Number }
	},
	data: () => ({
		namespace: null,
		isConnected: true,
	}),
	computed: {
		segment() {
			const list = [
				...this.projectSegments,
				...this.projectResources
			];

			return list.find(v => v._id === this.entityId);
		},
		...mapGetters(['isWsConnected']),
	},
	watch: {
		entityId: {
			immediate: false,
			handler: 'refresh'
		},
	},
	created() {
		this.updateNamespace(this.entityId);
	},
	methods: {
		onUserListUpdate(users) {
			this.$emit('onUserListUpdate', users);
		},
		updateNamespace(entityId) {
			this.namespace = `${this.projectId}/${entityId}`;
		},
		refresh(newEntityId) {
			this.updateNamespace(newEntityId);
		},
	}
};
</script>

<style lang="scss">
</style>
