import draggable from 'vuedraggable';

export default {
	components: { draggable },
	data: () => ({
		isDraggingDropSortable: false,
		dropAreaData: null,
		dropAreaGroup: 'g1',
		dropAreaFamily: 'f1',
		dropAreaElement: null
	}),
	computed: {
		isDragAreaHover() {
			return !!this.dropAreaData;
		},
		dragAreaOptions() {
			return {
				group: { name: this.dropAreaGroup },
				swapThreshold: typeof this.dropAreaThreshold === 'number'
					? this.dropAreaThreshold
					: 1,
				disabled: this.dropAreaDisabled,
				handle: '.none-handle', // prevent drop area draggable
				_family: this.dropAreaFamily,
				_dropArea: {
					componentGetter: () => this,
					elementGetter: () => this.$el
				}
			};
		}
	},
	mounted() {
		this._dropSortableStart = (...args) => {
			this.isDraggingDropSortable = true;
			this.onDropSortableStart(...args);
		};

		this._dropSortableEnd = (...args) => {
			this.isDraggingDropSortable = false;
			this.onDropSortableEnd(...args);
		};

		this.$root.$on('app:dropSortable:start', this._dropSortableStart);
		this.$root.$on('app:dropSortable:end', this._dropSortableEnd);
	},
	beforeDestroy() {
		this.$root.$off('app:dropSortable:start', this._dropSortableStart);
		this.$root.$off('app:dropSortable:end', this._dropSortableEnd);
	},
	methods: {
		onDropSortableStart() {
		},
		onDropSortableEnd() {
		},
		setDropData(data) {
			if (data === this.dropAreaData) return;
			this.dropAreaData = data;
		},
		unsetDropData() {
			this.dropAreaData = null;
		},
		// eslint-disable-next-line no-unused-vars
		applyDropData(data) {
		}
	}
};
