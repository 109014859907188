<template lang="pug">
	.project-workbench-pane.splitpanes__pane(
		:style="style",
		:class="[tabClass]",
		v-on="$listeners"
	)
		.project-workbench-pane-header(v-if="!hideHeader")
			.pane-header-item
				.pane-header-title {{ displayTitle }}
			.pane-header-item
				.pane-header-close(@click="closePane")

		// Content with drag area
		draggable.full-height(v-bind="dragAreaOptions", v-if="dragArea")
			.project-workbench-pane-content
				slot

		// Plain content
		.project-workbench-pane-content(v-else)
			slot
</template>

<script>
import _get from 'lodash/get';
import { Pane } from 'splitpanes';
import dropAreaMixin from '../drop-area-mixin';

export default {
	extends: Pane,
	name: 'ProjectWorkbenchPane',
	mixins: [dropAreaMixin],
	props: {
		title:      { type: String, default: 'Empty pane' },
		hideHeader: { type: Boolean, default: false },
		dragArea:   { type: Boolean, default: true },
		projectId:  { type: String },
		paneIdx:    { type: Number, default: null }
	},
	data: () => ({
		dropAreaFamily: null
	}),
	computed: {
		tabClass() {

			return [
				'tab-default',
				{ 'hide-content': !!this.dropAreaElement }
			];
		},
		displayTitle() {
			return (
				_get(this.dropAreaData, 'name') ||
				_get(this.paneData, 'name') ||
				this.title ||
				'Empty pane'
			);
		}
	},
	watch: {
	},
	created() {
		// override options to disable drag&drop
		this.dragAreaOptions.disabled = true;
	},
	methods: {
		closePane() {
			switch(this.groupType) {
				case 'content': {
					this.$mixPanel.trackEvent('Content Removed from Pane', 'Project', 'Project Pane');
					break;
				}
				case 'resource': {
					this.$mixPanel.trackEvent('Note Removed from Pane', 'Project', 'Project Pane');
					break;
				}
			}
			this.$store.dispatch('projects/removeItemFromPane', {
				projectId: this.projectId,
				paneIdx: this.paneIdx
			});
			this.$root.$emit('pane-item-unloaded', {paneIdx: this.paneIdx});
		},
		dropAreaCustomCheckMove({ draggedElement }) {
			if (draggedElement.isFolder) {
				return false;
			}
		},
		applyDropData({ data, payload }) {
			const paneIdx   = this.paneIdx || 0;
			const projectId = data.project;
			const entityType = payload.type;

			const attrs = {};

			switch (entityType) {
				case 'segment':
					attrs.entityId = data._id;
					break;

				case 'resource':
					attrs.entityId = data._id;
					break;
				case 'audio':
					attrs.entityId = data._id;
					break;

				default:
					throw new TypeError(`Unknown DnD payload type: ${entityType}`);
			}

			if (!projectId || typeof projectId !== 'string') {
				throw new TypeError('Failed to extract project id');
			}

			switch(data.group) {
				case 'content': {
					this.$mixPanel.trackEvent('Content Added to Pane', 'Project', 'Project Pane');
					break;
				}
				case 'resource': {
					this.$mixPanel.trackEvent('Note Added to Pane', 'Project', 'Project Pane');
					break;
				}
			}

			this.$store.dispatch('projects/updateLayoutPane', {
				projectId,
				paneIdx,
				newState: {
					entityType,
					attrs
				}
			});
		}
	}
};
</script>

<style lang="scss">
	.project-workbench-pane.splitpanes__pane {
		overflow: visible;
	}
</style>
