<template lang="pug">
	b-modal#prose_mirror_add_link_modal(centered, size="md", :no-close-on-backdrop="true", title="Insert a link", @shown="onShown")
		transition(name="slide-fade")
		div
			.form-group
				label Enter the address
				input.form-control.form-control-lg.padding-right(
					@keyup.enter="submit",
					placeholder="https://",
					name="url",
					:ref="'url'",
					v-model="model.url")
		template(slot="modal-footer")
			img(src="~@/assets/illustrations/books.svg")
			.action-buttons
				.btn.btn-link.sm.absolute-position(@click="close") Cancel
				ladda.btn.btn-app-blue2.sm(
					:loading="isSubmit",
					@click="submit",
					data-style="zoom-out",
					:disabled="isSubmitButtonDisabled"
				) Insert link

</template>

<script>

import { isUrlValid } from '@/utils/util';

export default {
	name: 'AddLinkModal',
	data: () => ({
		isSubmit: false,
		onUrlAdded: null,
		model: {
			url: null
		}
	}),
	computed: {
		isSubmitButtonDisabled() {
			if (this.model.url) {
				return !isUrlValid(this.model.url);
			}

			return true;
		}
	},
	mounted() {
		this._openHandler = ({ projectId, onUrlAdded, data = {} }) => {
			this.show();
			this.onUrlAdded = onUrlAdded;
		};

		this.$root.$on('app:modal:prose_mirror_add_link_modal', this._openHandler);
	},
	beforeDestroy() {
		this.$root.$off('app:modal:prose_mirror_add_link_modal', this._openHandler);
	},
	methods: {
		onShown() {
			this.isSubmit = false;
			this.model.url = null;

			if (this.$refs.form) {
				this.$refs.form.$validator.reset();
				this.$refs.form.errors.clear();
			}

			setTimeout(() => {
				this.$refs.url.focus();
			}, 100);
		},
		async submit(e) {
			e.preventDefault();
			e.stopPropagation();

			if (this.model.url) {
				let url = this.model.url;
				url = !url.startsWith('https://') ? `https://${url}` : url;
				this.onUrlAdded(url);
				this.close();
				return;
			}
		},
		show() {
			this.$root.$emit('bv::show::modal', 'prose_mirror_add_link_modal');
		},
		close() {
			this.$root.$emit('bv::hide::modal', 'prose_mirror_add_link_modal');
		},
	}
};
</script>

<style lang="scss" scoped>
	.subheading {
		margin-top: -50px;
		margin-bottom: 3rem;
		color: black;
	}

</style>
