import Vue from 'vue';
import VueAxios from 'vue-axios';
import Datepicker from 'vue2-datepicker';

import VueSweetAlert from '@/lib/swal';
import VeeValidate from '@/lib/validator';
import axios from '@/lib/axios';

import Ladda from '@/components/Ladda';
import PaginationMore from '@/components/PaginationMore';
import LocalPagination from '@/components/LocalPagination';
import SlideLoader from '@/components/SlideLoader';

import App from './App.vue';
import router from './router';
import store from './store';
// import initHelpHero from 'helphero';

import mixPanel from '@/lib/mixpanel';
import Multiselect from "vue-multiselect";

mixPanel.init(process.env.VUE_APP_MIXPANEL_KEY);
Vue.prototype.$mixPanel = mixPanel;
// window.helpHero = initHelpHero(process.env.VUE_APP_HELPHERO_KEY);
Vue.config.productionTip = false;

Vue.use(VeeValidate, { locale: 'en', events: 'input' });
Vue.use(VueSweetAlert, { heightAuto: false });
Vue.use(VueAxios, axios);

Vue.component('ladda', Ladda);
Vue.component('LocalPagination', LocalPagination);
Vue.component('PaginationMore', PaginationMore);
Vue.component('SlideLoader', SlideLoader);
Vue.component('Datepicker', Datepicker);
Vue.component('multiselect', Multiselect);

// Fix for Swal library, since it does not have definition of forEach on NodeList
if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
	HTMLCollection.prototype.forEach = Array.prototype.forEach;
}

// Reset web socket connection state
store.dispatch('setWebSocketState', 'disconnected');

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
