<template lang="pug">
	div
		input.inline-input(type='text', v-if="edit", :value="valueLocal", @blur="saveValue", @keyup.enter="$event.target.blur()", v-focus="")
		.read-only-val(v-else, @click="edit = true")
			| {{valueLocal}}
</template>
<script>
	export default {
	props: ['value'],
	data () {
		return {
			edit: false,
			valueLocal: this.value
		};
	},
	watch: {
		value: function() {
			this.valueLocal = this.value;
		}
	},
	directives: {
		focus: {
			inserted (el) {
				el.focus();
			}
		}
	},
	methods: {
		saveValue(e) {
			this.valueLocal = e.target.value;
			this.edit = false;
			this.$emit('valueChanged', this.valueLocal);
		}
	}
};
</script>
<style scoped>
	.inline-input {
		width: 100%;
		color: #353b54;
		font-family: "Proxima Nova Semibold";
		font-size: 16px;
		letter-spacing: .4px;
		border: none;
		border-bottom: 1px solid #d8d9de;
		margin-left: 0px;
		margin-top: -1px;
	}

	.read-only-val {
		width: 100%;
		color: #353b54;
		font-family: "Proxima Nova Semibold";
		font-size: 16px;
		letter-spacing: .4px;
		margin-left: 2px;
	}
</style>
