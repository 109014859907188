import _get from 'lodash/get';

export default {
	computed: {
		maxProjectsReached() {
			if (!this.$store.state.user.account.limits) {
				return false;
			}

			return this.$store.state.user.account.limits.maxProjectsReached;
		},
		isProjectHasSegments() {
			return this.projectSegments.length > 0;
		},
		isProjectHasResources() {
			return this.projectResources.length > 0;
		},
		isProjectHasPanes() {
			return this.projectLayoutPanes.length > 0;
		},
		allItems() {
			return [...this.projectSegments, ...this.projectResources];
		},
		projectSegments() {
			if (!this.$store.state.projects.mapSegments) {
				return [];
			}

			const res = this.$store.state.projects.mapSegments[this.projectId] || [];
			return res.filter(x => x.group === 'content');
		},
		projectResources() {
			if (!this.$store.state.projects.mapResources) {
				return [];
			}

			const res = this.$store.state.projects.mapResources[this.projectId] || [];
			return res.filter(x => x.group === 'resource');
		},
		projectLayoutView() {
			const { mapLayout } = this.$store.state.projects;

			if (!mapLayout || !mapLayout[this.projectId]) {
				return 2;
			}

			return mapLayout[this.projectId].viewMode || 2;
		},
		projectLayoutPanes() {
			const { mapLayout } = this.$store.state.projects;

			if (!mapLayout || !mapLayout[this.projectId]) {
				return [];
			}

			return (mapLayout[this.projectId].panes || [])
				.filter(v => v && typeof v === 'object');
		},
		projectLayoutActiveSegments() {
			return this.projectSegments
				.filter(v => this.projectLayoutActiveSegmentsMap[v._id]);
		},
		projectLayoutActiveSegmentsMap() {
			const ids = this.projectLayoutPanes
				.filter(v => v.entityType === 'segment' && _get(v, 'attrs.entityId'))
				.map(v => v.attrs.entityId);

			const result = {};

			ids.forEach(id => this.$set(result, id, true));

			return result;
		},
		projectLayoutActiveResourcesMap() {
			const ids = this.projectLayoutPanes
				.filter(v => v.entityType === 'resource' && _get(v, 'attrs.entityId'))
				.map(v => v.attrs.entityId);

			const result = {};

			ids.forEach(id => this.$set(result, id, true));

			return result;
		}
	},
	methods: {
		isSegmentActive(value) {
			if (typeof value === 'object') {
				value = value._id;
			}

			return !!this.projectLayoutActiveSegmentsMap[value];
		},
		isResourceActive(value) {
			if (typeof value === 'object') {
				value = value._id;
			}

			return !!this.projectLayoutActiveResourcesMap[value];
		},
		getPaneColorByPaneIdx(paneIdx) {
			switch(paneIdx) {
				case 0:
					return '#54e3e7';
				case 1:
					return '#ce41f9';
				case 2:
					return '#9df08c';
				default:
					return '';
			}
		}
	}
};
