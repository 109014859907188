import Vue from 'vue';
import _get from 'lodash/get';

export function SET(state, [key, value]) {
	Vue.set(state, key, value);
}

export function UPDATE_PROJECT(state, updatedProject) {
	const updatedList = [...state.displayList];
	const indexToUpdate = updatedList.findIndex(x => x._id === updatedProject._id);
	updatedList[indexToUpdate] = updatedProject;
	updatedList.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
	Vue.set(state, 'displayList', updatedList);
}

export function REMOVE_PROJECT(state, projectId) {
	let updatedList = [...state.displayList];
	const indexToRemove = updatedList.findIndex(x => x._id === projectId);
	updatedList.splice(indexToRemove, 1);

	// Remove segments related to the project
	const segmentsStatesData = localStorage.getItem('segmentsStates');
	if (segmentsStatesData !== null) {
		const segmentsStates = JSON.parse(segmentsStatesData);
		const newState = segmentsStates.filter(x => x.projectId !== projectId);
		localStorage.setItem('segmentsStates', JSON.stringify(newState));
	}

	delete state.mapLayout[projectId];

	Vue.set(state, 'displayList', updatedList);
}

export function ADD_PROJECT(state, project) {
	const updatedList = [...state.displayList, project];
	updatedList.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
	Vue.set(state, 'displayList', updatedList);
}


export function SET_DISPLAY_LIST(state, projects) {
	if (!state.displayList) {
		state.displayList = [];
	}
	Vue.set(state, 'displayList', projects);
}

export function ADD_SEGMENT(state, payload) {
	const group = payload.segment.group;
	if (group === 'content') {
		state.mapSegments[payload.params.projectId].push(payload.segment);
	} else if (group === 'resource') {
		state.mapResources[payload.params.projectId].push(payload.segment);
	}
}

export function REMOVE_SEGMENT(state, payload) {
	const items = state.mapSegments[payload.projectId];
	const index = items.findIndex(x => x._id === payload.segmentId);
	if (index !== -1) {
		items.splice(index, 1);
	}

	state.mapSegments[payload.projectId] = items;
}

export function ADD_RESOURCE(state, payload) {
	const group = payload.resource.group;
	if (group === 'content') {
		state.mapSegments[payload.params.projectId].push(payload.resource);
	} else if (group === 'resource') {
		state.mapResources[payload.params.projectId].push(payload.resource);
	}
}

export function REMOVE_RESOURCE(state, payload) {
	const items = state.mapResources[payload.projectId];
	const index = items.findIndex(x => x._id === payload.resourceId);
	if (index !== -1) {
		items.splice(index, 1);
	}

	state.mapResources[payload.projectId] = items;
}

export function SET_MAP_SEGMENTS(state, [projectId, list]) {
	if (!state.mapSegments) {
		state.mapSegments = {};
	}

	if (list === null) {
		delete state.mapSegments[projectId];
	} else if (Array.isArray(list)) {
		const sortedSegments = list.sort((a, b) => (a.index > b.index) ? 1 : (a.index < b.index) ? -1 : 0);
		Vue.set(state.mapSegments, projectId, sortedSegments);
	} else {
		throw new TypeError('Invalid list array');
	}
}

export function SET_MAP_RESOURCES(state, [projectId, list]) {
	if (!state.mapResources) {
		state.mapResources = {};
	}

	if (list === null) {
		delete state.mapResources[projectId];
	} else if (Array.isArray(list)) {
		const sortedResources = list.sort((a, b) => (a.index > b.index) ? 1 : (a.index < b.index) ? -1 : 0);
		Vue.set(state.mapResources, projectId, sortedResources);
	} else {
		throw new TypeError('Invalid list array');
	}
}

export function SET_MAP_LAYOUT_VIEW_MODE(state, [projectId, viewMode]) {
	if (!state.mapLayout) {
		Vue.set(state, 'mapLayout', {});
	}

	if (!state.mapLayout[projectId]) {
		Vue.set(state.mapLayout, projectId, {});
	}

	Vue.set(state.mapLayout[projectId], 'viewMode', viewMode);

	const panes = _get(state, ['mapLayout', projectId, 'panes'], []);
	//const recentClosed = _get(state, ['mapLayout', projectId, 'recentClosed'], []);
	// open recent closed panes
	// if (viewMode > panes.length) {
	// 	for (let idx = panes.length + 1; idx <= viewMode; idx++) {
	// 		const revertPane = recentClosed[0];
	//
	// 		if (revertPane) {
	// 			console.debug('back recent closed:', revertPane);
	// 			panes.push(revertPane);
	// 			recentClosed.shift();
	// 		}
	// 	}
	//
	// 	SET_MAP_LAYOUT_PANES(state, [projectId, panes]);
	// }
	Vue.set(state.mapLayout[projectId], 'recentClosed', []);

	if (viewMode < panes.length) {
		for (let i = viewMode; i < panes.length; i++) {
			Vue.set(state.mapLayout[projectId].panes, i, {active: false, entityType: null, attrs: {entityId: null}});
		}
		const activePaneIndex = panes.findIndex(pane => !!pane.active);
		if (activePaneIndex < 0) {
			Vue.set(state.mapLayout[projectId].panes[0], 'active', true);
		}
	}
}

export function SET_MAP_LAYOUT_PANES(state, [projectId, panesList]) {
	if (!state.mapLayout) {
		Vue.set(state, 'mapLayout', {});
	}

	if (!state.mapLayout[projectId]) {
		Vue.set(state.mapLayout, projectId, {});
	}

	Vue.set(state.mapLayout[projectId], 'panes', panesList);
}

export function SET_PANE_ACTIVE(state, [projectId, paneIndex]) {
	if (!state.mapLayout) {
		Vue.set(state, 'mapLayout', {});
	}
	if (!state.mapLayout[projectId]) {
		Vue.set(state.mapLayout, projectId, {});
	}
	const existingPanes = state.mapLayout[projectId].panes || [];
	const modifiedPanes = existingPanes.map((pane, index) => {
		pane.active = index === paneIndex;
		return pane;
	});
	Vue.set(state.mapLayout[projectId], 'panes', modifiedPanes);
}

export function UPDATE_MAP_LAYOUT_PANE_BY_IDX(state, [projectId, idx, newData]) {
	if (!state.mapLayout) {
		state.mapLayout = {};
	}

	if (!state.mapLayout[projectId]) {
		state.mapLayout[projectId] = {};
	}

	if (!state.mapLayout[projectId].panes) {
		state.mapLayout[projectId].panes = [];
	}

	switch (true) {
		case !newData || typeof newData !== 'object':
			throw new TypeError('invalid state data type');

		case typeof newData.entityType !== 'string':
			throw new TypeError('invalid state.entityType string');

		case newData.attrs && typeof newData.attrs !== 'object':
			throw new TypeError('invalid state.attrs object');
	}

	Vue.set(state.mapLayout[projectId].panes, idx, newData);
}

export function ADD_MAP_RECENT_CLOSED_PANE(state, [projectId, pane]) {
	if (!state.mapLayout) {
		Vue.set(state, 'mapLayout', {});
	}

	if (!state.mapLayout[projectId]) {
		Vue.set(state.mapLayout, projectId, {});
	}

	if (!state.mapLayout[projectId].recentClosed) {
		Vue.set(state.mapLayout[projectId], 'recentClosed', []);
	}

	const recentClosed = state.mapLayout[projectId].recentClosed;

	recentClosed.unshift(pane);

	while (recentClosed.length > 3) {
		recentClosed.pop();
	}
}

export function UPDATED_ENTITY(state, entity) {
	state.updatedEntity = entity;
}

export function SET_SEGMENT_CONTENT(state, payload) {
	let item;
	if (payload.group === 'resource') {
		item = state.mapResources[payload.projectId].find(x => x._id === payload.segmentId);
	} else if (payload.group === 'content') {
		item = state.mapSegments[payload.projectId].find(x => x._id === payload.segmentId);
	}

	item.meta.content = payload.content;
}

export function REMOVED_FROM_PROJECT(state, status) {
	state.removedFromProject = status;
}

export function REMOVED_FROM_PROJECT_MESSAGE(state, message) {
	state.removedFromProjectMessage = message;
}

export function SET_PROSEMIRROR_ITEM_CURSOR_POSITION(state, data) {
	let cursorPosition = state.mapLayout[data.projectId].cursorPosition;
	if (!cursorPosition) {
		cursorPosition = {};
	}

	merge(cursorPosition,{
		[data.entityId]: {
			[data.paneIdx]: data.currentPosition
		}}
	);

	state.mapLayout[data.projectId].cursorPosition = cursorPosition;
}

export function REMOVE_CURSOR_DATA(state, data) {
	const cursorPosition = state.mapLayout[data.projectId].cursorPosition;
	if (!cursorPosition) {
		return;
	}

	delete cursorPosition[data.entityId];
}

export function PROJECT_UPDATED(state, project) {
	state.projectUpdated = project;
}

function merge(current, update) {
  Object.keys(update).forEach(function(key) {
    // if update[key] exist, and it's not a string or array,
    // we go in one level deeper
    if (current.hasOwnProperty(key)
        && typeof current[key] === 'object'
        && !(current[key] instanceof Array)) {
      merge(current[key], update[key]);

    // if update[key] doesn't exist in current, or it's a string
    // or array, then assign/overwrite current[key] to update[key]
    } else {
      current[key] = update[key];
    }
  });
  return current;
}
