<template lang="pug">
	splitpanes.project-workbench-splitpanes.default-theme(v-bind="$attrs")
		slot
</template>

<script>
import { Splitpanes } from 'splitpanes';

export default {
	name: 'ProjectWorkbenchSplitpanes',
	components: { Splitpanes },
	props: {
	},
	data: () => ({
	}),
	computed: {
	},
	watch: {
	},
	created() {
	},
	methods: {
	}
};
</script>

<style lang="scss">
</style>
