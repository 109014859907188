<template lang="pug">
	.project-workbench-pane.project-workbench-pane-segment.splitpanes__pane(
		:style="style",
		:class="[tabClass, { hover: isDragAreaHover }, {active: isPaneActive}]"
		v-on="$listeners"
		@click="setPaneAsActive($event)"
	)
		slide-loader(:display="isLoading")
		.project-workbench-pane-header(v-if="paneData")
			.header(v-bind:style="headerGridColumns")
				.diagonal-split-background(:style="{'background-image': segmentColor}")
				b-tooltip(:disabled.sync="disabledTooltip", :target="`${id}`", offset=50, delay=500) {{ paneData.name }}
				//- .pane-header-title.ellipsis(v-show="!editSegmentNameInline", :id="id", :ref="'title'", @mouseenter="onMouseEnter", @mouseleave="onMouseLeave", @click.stop="editTitle", v-bind:class="{gray: isUntitled}") {{ paneData.name }}
				click-to-edit.ellipsis(:value="paneData.name", @valueChanged="updateSegmentName", style="margin-top: 20px")
				//- input.edit-resource-name(v-show="editSegmentNameInline", :ref="'resourceNameInput'", type="text", :value="paneData.name", focus, @keyup.enter="updateSegmentName", @keyup.esc="editSegmentNameInline = false")
				.user-avatars
					.collaboration-users(v-if="displayUsers")
						ul
							li(v-for="user in displayUsers", :title="user.name", :style="{'background-color': user.color}", v-if="getUserInitials(user.name) !== ''") {{getUserInitials(user.name)}}
							li.count(v-if="hiddenCollaboratorsCount", :title="hiddenCollaboratorsString") +{{hiddenCollaboratorsCount}}
				.close-cell(v-if="isPaneActive")
					.pane-header-close(@click="closePaneInner")

		draggable.project-workbench-pane-content(v-bind="dragAreaOptions", v-if="paneData")
			.document-editor(v-show="paneData")
				.document-editor__editable-container
					prosemirror-editor-container(v-if="isProseMirror", :projectId="projectId", :entityId="entityId", :paneIdx="paneIdx", @onUserListUpdate="onUserUpdate")

		draggable.max-height(v-if="!paneData", v-bind="dragAreaOptions")
			empty-segment-state(:projectId="projectId", :paneIdx="paneIdx")
</template>

<script>
import ProjectWorkbenchPane from './ProjectWorkbenchPane.vue';
import EmptySegmentState from './EmptySegmentState';
import projectMixin from '../project-mixin';
import ProseMirrorEditorContainer from './ProseMirror/ProseMirrorEditorContainer';
import ClickToEdit from './ClickToEdit';

export default {
	extends: ProjectWorkbenchPane,
	mixins: [projectMixin],
	name: 'ProjectWorkbenchPaneSegment',
	components: {
		'prosemirror-editor-container': ProseMirrorEditorContainer,
		'empty-segment-state': EmptySegmentState,
		'click-to-edit': ClickToEdit
	},
	props: {
		projectId: { type: String, required: true },
		entityId:  { type: String },
		paneIdx:   { type: Number }
	},
	data: () => ({
		id: null,
		isLoading: false,
		users: [],
		disabledTooltip: false,
		editSegmentNameInline: false,
		collaboratorsLabelBaseWidth: 25,
		hiddenCollaboratorsCount: 0,
		hiddenCollaboratorsString: '',
		headerGridColumns: {
			gridTemplateColumns: `25px 1fr 25px 30px`
		}
	}),
	computed: {
		displayUsers() {
			const result = [];
			if (!this.users) {
				return result;
			}

			this.users.forEach(user => {
				if (result.findIndex(x => x.name === user.name && x.color === user.color) === -1) {
					result.push(user);
				}
			});
			return result;
		},
		noSegments() {
			return this.projectSegments.length === 0 && !this.paneData;
		},
		segment() {
			const list = [
				...this.projectSegments,
				...this.projectResources
			];

			return list.find(v => v._id === this.entityId);
		},
		paneData() {
			return this.segment;
		},
		segmentColor() {
			if (this.segment) {
				const baseColor = this.isPaneActive ? this.getPaneColorByPaneIdx(this.paneIdx) : '#C0C0C0';
				return `-webkit-linear-gradient(135deg, #FFFFFF 50%, ${baseColor} 50%)`;
			}
			return '';
		},
		isProseMirror() {
			return this.segment && this.segment.type === 'prosemirror';
		},
		isPaneActive() {
			return this.$store.getters['projects/getPaneActiveStatus'](this.projectId, this.paneIdx);
		},
		isUntitled() {
			return this.displayTitle === 'Untitled item';
		},
	},
	methods: {
		onUserUpdate(users) {
			this.users = users;
			this.setCollaboratorsContainerWidth();
		},
		setCollaboratorsContainerWidth() {
			this.collaboratorsLabelBaseWidth = 25;
			this.hiddenCollaboratorsCount = 0;
			const result = [];
			if (!this.users) {
				return;
			}
			this.users.forEach(user => {
				if (result.findIndex(x => x.name === user.name && x.color === user.color) === -1) {
					result.push(user);
					this.collaboratorsLabelBaseWidth += 41;
				}
			});
			// When we have more than 3 collaborators we are hiding the extra ones
			// because we do not want to brake layout on mobile devices.
			// New design should take that into account.
			if (this.collaboratorsLabelBaseWidth >= 148) {
				// TODO: change header look when design is finished.
				this.collaboratorsLabelBaseWidth = 148;
				this.hiddenCollaboratorsCount = this.users.length - 3;
				if (this.hiddenCollaboratorsCount > 0) {
					this.collaboratorsLabelBaseWidth += 25;
				}
				this.headerGridColumns.gridTemplateColumns = `25px 1fr ${this.collaboratorsLabelBaseWidth}px 30px`;
				const hiddenCollaborators = this.users.slice(3);
				this.hiddenCollaboratorsString = hiddenCollaborators.map(user => user.name).join(', ');
				this.users = this.users.slice(0, 3);
				return;
			}
			this.headerGridColumns.gridTemplateColumns = `25px 1fr ${this.collaboratorsLabelBaseWidth}px 30px`;
		},
		getUserInitials(name) {
			if (!name) {
				return '';
			}
			return name.match(/\b(\w)/g).join('');
		},
		closePaneInner() {
			switch(this.segment.group) {
				case 'content': {
					this.$mixPanel.trackEvent('Content Removed from Pane', 'Project', 'Project Pane');
					break;
				}
				case 'resource': {
					this.$mixPanel.trackEvent('Note Removed from Pane', 'Project', 'Project Pane');
					break;
				}
			}
			this.closePane();
		},
		updateSegmentName(newName) {
			const data = {
				name: newName,
				group: this.segment.group,
			};
			this.$mixPanel.trackEvent('Rename Content', 'Project', 'Pane');
			this.$store.dispatch('projects/updateSegment', {
				projectId: this.projectId,
				segmentId: this.entityId,
				fetch: true,
				data
			});
		},
		editTitle() {
			if (!this.isPaneActive) {
				this.setPaneAsActive(null);
				return;
			}
			this.editSegmentName();
		},
		editSegmentName() {
			this.editSegmentNameInline = !this.editSegmentNameInline;
			if (this.editSegmentNameInline) {
				document.addEventListener('click', this.editSegmentName);
			} else {
				document.removeEventListener('click', this.editSegmentName);
			}
			this.$nextTick(() => {
				if (this.$refs.resourceNameInput) {
					this.$refs.resourceNameInput.focus();
				}
			});
		},
		onMouseLeave() {
			this.disabledTooltip = false;
		},
		onMouseEnter() {
			const element = this.$refs.title;
			this.disabledTooltip = !(element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth);
		},
		setPaneAsActive(event) {
			if (this.isPaneActive) {
				return;
			}
			if (event?.target?.className === 'pane-header-close') {
				return;
			}
			this.$store.dispatch('projects/setPaneActive', {projectId: this.projectId, paneIndex: this.paneIdx})
				.then(() => {
					this.$root.$emit('segment-item-loaded', {id: this.entityId, paneIdx: this.paneIdx});
					this.$root.$emit('close-inline-edit', this.paneIdx);
				});
		},
	},
	mounted() {
		// override options to disable drag&drop
		this.dragAreaOptions.disabled = true;
	},
	created() {
		this.$root.$on('close-inline-edit', paneIndex => {
			if (this.paneIdx !== paneIndex) {
				setTimeout(() => this.editSegmentNameInline = false, 0);
			}
		});
	}
};
</script>

<style lang="scss">
	.project-workbench-pane-segment {
		&.active {
			.pane-header-title {
				cursor: pointer;
			}
		}
		.project-workbench-pane-header {
			.header {
				display: grid;
				//grid-template-columns: 25px 1fr 220px 30px;
				width: 100%;
				height: 100%;
				.pane-header-title {
          padding-left: 10px;
					&.ellipsis {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						width: 100%;
					}
					&.gray {
						color: #C0C0C0;
					}
				}
				.edit-resource-name {
					width: 100%;
					color: #353B54;
					font-family: $proxima-semi;
					font-size: 16px;
					letter-spacing: 0.4px;
					border: none;
					border-bottom: 1px solid #d8d9de;
					margin: auto 0 auto 8px;
				}
			}
		}
		.project-workbench-pane-content {
			height: calc(100% - 63px);
		}
		&.hover {
			opacity: 0.7;
			pointer-events: none;
		}
			.diagonal-split-background{
				width: 25px;
				height: 25px;
			}
			.user-avatars {
				text-align: right;
				padding-right: 10px;
				margin: auto 0;
			}
			.collaboration-users {
				ul {
					display: inline-flex;
					list-style-type: none;
					padding: 0;
					margin: 0;
					li {
						cursor: default;
						color: white;
						margin-left: 5px;
						width: 36px;
						height: 36px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						padding-left: 10px;
						&.count {
							padding: 0;
							width: 20px;
							color: #172937;
							font-size: 14px;
						}
					}
				}
			}
			.close-cell {
				align-self: center;
			}
			.pane-header-close {
				display: inherit;
			}
		}
</style>
