<template lang="pug">
	b-modal#api-error-modal(
		size="md", centered,
		:no-close-on-backdrop="false",
		:hide-footer="true",
		:hide-header-close="false",
		@change="setVisibility($event)"
	)
		template(slot="modal-header")
			button.close(@click="close") ×
			img(src="@/assets/illustrations/login-error.svg")
		transition(name="slide-fade")
		h4.title {{config.title || 'Something went wrong.'}}
		div.text
			p(v-if="config.text && config.text.length") {{config.text}}
			template(v-else)
				p We’re aware of it and will fix it ASAP.
		ladda.btn.btn-app-blue2.no-shadow.sm(:loading="false", @click="redirect") {{config.buttonText || 'Go back to my cool project'}}
</template>

<script>
	import {BModal} from 'bootstrap-vue';
	import {mapGetters} from "vuex";

	export default {
		name: 'ApiErrorModal',
		components: {BModal},
		computed: {
			...mapGetters({config: 'apiErrorModalConfig'}),
		},
		data:	() => ({
			isVisible: false,
		}),
		watch: {
			config(newValue) {
				if (newValue.active && !this.isVisible) {
					this.$root.$emit('bv::show::modal', 'api-error-modal');
				}
			}
		},
		methods: {
			close() {
				this.setVisibility(false);
				this.$store.commit('RESET_API_ERROR_MODAL_CONFIG');
				this.$root.$emit('bv::hide::modal', 'api-error-modal');
			},
			redirect() {
				this.setVisibility(false);
				if (this.config.redirectRoute?.length) {
					this.$store.commit('RESET_API_ERROR_MODAL_CONFIG');
					window.location.href = `${window.location.origin}/${this.config.redirectRoute}`;
					return;
				}
				window.location.reload();
			},
			setVisibility(isVisible) {
				this.isVisible = isVisible;
			}
		},
		beforeDestroy() {
			this.setVisibility(false);
		}
	};
</script>

<style lang="scss">
	#api-error-modal___BV_modal_content_ {
		border-radius: 16px;
		box-shadow: 0 35px 50px 0 rgba(39, 63, 82, 0.15), 0 -15px 50px 0 rgba(39, 63, 82, 0.15);
		width: 540px;
		height: 385px;
		.modal-header {
			margin: 0 auto;
			padding: 38px 0 25px 0;
			.close {
				position: absolute;
				top: 15px;
				right: 15px;
				display: block;
				margin: 0;
				padding: 0;
				color: #172937;
				opacity: .4;
			}
		}
		.modal-body {
			padding-top: 0;
			text-align: center;
			font-family: 'Proxima Nova Semibold', sans-serif;
			.title {
				color: #273F52;
				font-size: 24px;
				letter-spacing: -0.1px;
				line-height: 29px;
				margin-bottom: 40px;
			}
			.text {
				color: #2F475E;
				font-size: 13px;
				line-height: 20px;
				p {
					margin-bottom: 0;
				}
			}
			.btn-app-blue2 {
				padding-left: 42px;
				padding-right: 42px;
				margin-top: 48px;
			}
		}
	}
	#api-error-modal___BV_modal_backdrop_ {
		background: #FFF;
		opacity: 0.75;
	}
</style>
