<template lang="pug">
	.project-workbench-toolbar
		.project-workbench-toolbar-container
			.project-info
				.round-circle(@click="backToProjects")
				.title {{projectName}}
			.preview-holder
				.preview
					a.btn.btn-no-fill.play-btn(@click.stop="toggleGenerateProjectOutputMenu")
						<svg class="play-icon" width="8px" height="11px" viewBox="0 0 8 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
							<title>;Play icon</title>
							<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
									<g id="future---first-time-project-details" transform="translate(-800.000000, -42.000000)" fill="#3E98F3">
											<g id="Group-3" transform="translate(778.000000, 30.000000)">
													<path d="M27.4042306,14.816764 L27.9116309,21.4850881 C27.9535338,22.035781 27.541078,22.5161747 26.9903851,22.5580775 C26.9398769,22.5619208 26.8891497,22.5619208 26.8386415,22.5580775 L20.1703174,22.0506772 C19.6196246,22.0087743 19.2071688,21.5283806 19.2490716,20.9776878 C19.2672047,20.7393809 19.3700869,20.5154484 19.5390824,20.3464528 L25.7000062,14.1855291 C26.0905305,13.7950048 26.7236955,13.7950048 27.1142198,14.1855291 C27.2832153,14.3545246 27.3860975,14.5784571 27.4042306,14.816764 Z" id=";Play-icon" transform="translate(23.000000, 17.646447) rotate(-45.000000) translate(-23.000000, -17.646447) "></path>
											</g>
									</g>
							</g>
						</svg>
						span(title="Assemble your project output in a PDF") Generate project output
				ul.generate-output(:class="[generateProjectOutputMenuOpen? 'show' : 'hide']")
					li
						a(@click="previewDocument('pdf')") Generate PDF file
					li
						a(@click="previewDocument('docx')") Generate DOCX file
					li
						a(@click="previewDocument('html')") Generate HTML file
				.last-generated(v-if="projectOutputs.length > 0")
					a(@click="openGeneratedOutput", target="_blank", :title="lastOutputTooltip") See latest {{latestOutputFormat}} version
			.links
				.members
					a.cursor(@click.stop="openMembersModal")
						span Project team
							members-list-modal(v-if="project", :project="project", source="'Project'")
				a.cursor.last(@click.stop="toggleSupportMenu") Help & feedback
					img(v-if="supportMenuOpen",src="@/assets/panel/toolbar/arrow-up.svg")
					img(v-else, src="@/assets/panel/toolbar/arrow-down.svg")
					ul.support-menu.toolbar-menu(:class="[supportMenuOpen? 'show' : 'hide']")
						li
							a(@click.stop="openHelp", :href="outsetaSupportUrl", target="_blank") Help
						li
							a(@click="openFeedback") Feedback
								feedback-modal
						//- li
						//- 	a(@click="restartTutorial") Restart tutorial
</template>

<script>
import _get from "lodash/get";
import FeedbackModal from "./FeedbackModal";
import MembersListModal from "../MembersListModal";
import randomImageMixin from "../../mixins/random-image";
import outseta from "@/mixins/outseta";
import { mapGetters } from "vuex";
// import { updateIntercomCustomProperty } from "../../lib/intercom";

export default {
	name: "ProjectWorkbenchToolbar",
	mixins: [randomImageMixin, outseta],
	components: {
		FeedbackModal: FeedbackModal,
		MembersListModal: MembersListModal,
	},
	props: {
		project: { type: Object, required: true },
		layoutView: { type: Number, default: 2 },
		openFeedbackModal: { type: Boolean, required: true, default: false },
	},
	data: () => ({
		supportMenuOpen: false,
		generateProjectOutputMenuOpen: false,
		updatedProjectDetails: undefined,
		editProjectNameInline: false,
		projectOutputs: [],
	}),
	computed: {
		...mapGetters({
			account: "user/account",
			projectUpdated: "projects/projectUpdated",
		}),
		projectName() {
			if (this.updatedProjectDetails) {
				return this.updatedProjectDetails
					? this.updatedProjectDetails.name
					: "";
			}
			return this.project ? this.project.name : "";
		},
		isOwner() {
			return this.project && this.project.userRole === "owner";
		},
		mirror() {
			return this.applyMirrorEffect();
		},
		writerImage() {
			return this.getRandomWriterImage();
		},
		latestOutput() {
			if (!this.projectOutputs) {
				return null;
			}

			const numberOfVersions = this.projectOutputs.length;
			const latest = this.projectOutputs[numberOfVersions - 1];
			return latest;
		},
		latestOutputFormat() {
			const latest = this.latestOutput;
			if (!latest || !latest.outputType) {
				return "";
			}

			return latest.outputType.toUpperCase();
		},
		lastOutputTooltip() {
			const latest = this.latestOutput;
			if (!latest) {
				return "";
			}

			const date = new Date(latest.createdAt);
			const time = date.toLocaleTimeString(undefined, { hour12: true });
			const dateString = date.toLocaleDateString(undefined, {
				weekday: "long",
				year: "numeric",
				month: "long",
				day: "numeric",
			});
			return `Created by ${latest.createdBy} on ${dateString} at ${time}`;
		},
		lastGenerateInfo() {
			if (!this.projectOutputs) {
				return "";
			}

			const numberOfVersions = this.projectOutputs.length;
			const latest = this.projectOutputs[numberOfVersions - 1];
			if (!latest) {
				return "";
			}

			const today = new Date();
			const latestCreatedAt = new Date(latest.createdAt);
			const diffInDays = Math.round(
				(today.getTime() - latestCreatedAt.getTime()) / (1000 * 3600 * 24)
			);
			if (diffInDays === 0) {
				return `Last version created earlier today`;
			}

			if (diffInDays === 1) {
				return `Last version created yesterday`;
			}

			return `Last version created ${diffInDays} days ago`;
		},
	},
	mounted: function () {
		this.$nextTick(() => {
			if (this.openFeedbackModal) {
				this.openFeedback();
			}

			// if (this.$route.query.restartTutorial) {
			// 	const tourId = process.env.VUE_APP_INTERCOM_TOUR_ID;
			// 	if (tourId) {
			// 		window.Intercom("startTour", tourId);
			// 	}
			// }
		});

		// updateIntercomCustomProperty("RestartOnboardingTutorial", false);

		// Update project user info, just in case that he skipped projects page where account info is obtained
		this.$store.dispatch("user/refresh");
	},
	watch: {
		projectUpdated: {
			immediate: false,
			handler: "handleProjectUpdate",
		},
		project: {
			immediate: false,
			handler: function (project) {
				if (project) {
					this.projectOutputs = project.output;
				}
			},
		},
	},
	methods: {
		handleProjectUpdate(updatedProject) {
			if (updatedProject.id === this.project._id) {
				this.projectOutputs = updatedProject.output;
			}
		},
		openGeneratedOutput() {
			this.$mixPanel.trackEvent("Preview Project", "Project", "Project Header");
			const numberOfVersions = this.projectOutputs.length;
			const latest = this.projectOutputs[numberOfVersions - 1];
			const url = `/output/${this.project._id}/${latest.outputId}`;
			window.open(url, "_blank").blur();
			window.focus();
		},
		backToProjects() {
			this.$mixPanel.trackEvent("Select back", "Project", "Project Header");
			this.$router.push({ name: "projects" });
		},
		previewDocument(outputType) {
			this.$store
				.dispatch("projects/generateOutput", {
					projectId: this.project._id,
					outputType: outputType,
				})
				.then((x) => {
					const data = {
						duration: 5,
						type: "success",
						message: `We’ll email a link to ${this.account.email} soon`,
					};
					this.$store.commit("SET_INLINE_NOTIFICATION_MODAL_CONFIG", data);
				});
		},
		openMembersModal() {
			this.$mixPanel.trackEvent(
				"Manage project team",
				"Project",
				"Project Header"
			);
			this.$root.$emit("bv::show::modal", "members-list-modal");
		},
		toggleSupportMenu() {
			this.supportMenuOpen = !this.supportMenuOpen;
			if (this.supportMenuOpen) {
				this.$mixPanel.trackEvent(
					"Select Help and Feedback",
					"Project",
					"Project Header"
				);
				document.addEventListener("click", this.toggleSupportMenu);
			} else {
				document.removeEventListener("click", this.toggleSupportMenu);
			}
		},
		toggleGenerateProjectOutputMenu() {
			this.generateProjectOutputMenuOpen = !this.generateProjectOutputMenuOpen;
			if (this.generateProjectOutputMenuOpen) {
				this.$mixPanel.trackEvent(
					"Select Generate Project Output",
					"Project",
					"Project Header"
				);
				document.addEventListener(
					"click",
					this.toggleGenerateProjectOutputMenu
				);
			} else {
				document.removeEventListener(
					"click",
					this.toggleGenerateProjectOutputMenu
				);
			}
		},
		openFeedback() {
			this.$mixPanel.trackEvent("Select Feedback", "Project", "Project Header");
			this.$root.$emit("bv::show::modal", "feedback_modal");
		},
		openHelp() {
			this.$mixPanel.trackEvent("Select Help", "Project", "Project Header");
		},
		isLayout(num) {
			return this.layoutView === num;
		},
		switchLayout(num) {
			this.$emit("onSwitchedLayout", num);
		},
		updateProjectName(e) {
			const projectName = e.srcElement.value;

			const data = {
				projectId: this.project._id,
				data: {
					name: projectName,
				},
			};
			this.$store.dispatch("projects/updateProject", data).then((x) => {
				this.updatedProjectDetails = x;
				this.editProjectNameInline = false;
			});
		},
		editProjectInfo() {
			this.$mixPanel.trackEvent("Rename Project", "Project", "Project Header");
			this.editProjectNameInline = !this.editProjectNameInline;
			if (this.editProjectNameInline) {
				document.addEventListener("click", this.editProjectInfo);
			} else {
				document.removeEventListener("click", this.editProjectInfo);
			}

			this.$nextTick(() => {
				if (this.$refs.projNameInput) {
					this.$refs.projNameInput.focus();
				}
			});
		},
		restartTutorial() {
			// this.$store.commit("SET_LOADING_OVERLAY_STATE", {
			// 	active: true,
			// 	message: "Sure, coming right up!",
			// });
			// this.$store
			// 	.dispatch("projects/restartTutorial")
			// 	.then((project) => {
			// 		updateIntercomCustomProperty("RestartOnboardingTutorial", true);
			// 		this.$store.commit("RESET_LOADING_OVERLAY_STATE");
			// 		if (project) {
			// 			this.$router.push({
			// 				name: "project.workbench",
			// 				params: { id: project.id },
			// 				query: {
			// 					restartTutorial: true,
			// 				},
			// 			});
			// 		}
			// 		setTimeout(() => {
			// 			updateIntercomCustomProperty("RestartOnboardingTutorial", false);
			// 		}, 10000);
			// 	})
			// 	.catch((e) => {
			// 		this.$store.commit("RESET_LOADING_OVERLAY_STATE");
			// 	});
		},
	},
};
</script>

<style lang="scss">
.generate-output {
	position: absolute;
	width: 200px;
	height: 115px;
	z-index: 1000;
	background: white;
	margin-left: 50px;
	box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.15);
	font-family: $proxima-medium;
}

.cursor {
	cursor: pointer !important;
	color: #3e98f3;
	line-height: 30px;
	&:hover {
		color: #3e98f3;
	}
	&.last {
		img {
			margin-left: 8px;
		}
	}
}
.toolbar-menu {
	height: 100px !important;
}

.last-generated {
	text-align: center;
	margin-top: 8px;
	color: #3e98f3;
	cursor: pointer !important;
	&:hover {
		color: #3e98f3;
		cursor: pointer !important;
		text-decoration: none;
	}
}
</style>
