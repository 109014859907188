<template lang="pug">
	.mobile-view
		.logo
			img(src="@/assets/common/logo-color.svg")
			.logo-sub Craft your story.
		.switch-to-desktop
			img(src="@/assets/mobile/phone.svg")
			img.arrow-margin(src="@/assets/mobile/arrow.svg")
			img(src="@/assets/mobile/desktop.svg")
			br
			.content-text.content-margin To make the most of Slice, we invite you to sign in or sign up on your laptop or desktop. See you there!
		.dash
			| &#x2015;&#x2015;
			span.content-text.or-color Or
			| &#x2015;&#x2015;
		.button-area
			img(src="@/assets/illustrations/typing.svg")
			br
			.btn.btn-no-fill(@click="goToWebsite") Check our website
</template>

<script>
export default {
	name: '',
	components: {},
	props: {},
	data: () => ({
	}),
	computed: {
	},
	watch: {
	},
	created() {
	},
	methods: {
		goToWebsite() {
			window.location.href = "https://slice.tech/";
		}
	}
};
</script>

<style lang="scss">
	body {
		background-color: #fafafa !important;
	}

	.mobile-view {
		display: grid;
		grid-template-rows: 25% 45% 5% 25%;
		width: 100%;
		height: 90vh;
		background-color: #fafafa !important;
		background: url('~@/assets/mobile/S_BG.svg') no-repeat center center fixed;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		justify-items: center;
		align-items: center;
		text-align: center;

		.logo {
			img {
				width: 5.5rem;
				height: 1.875rem;
			}
		}

		.arrow-margin {
			margin: 0 0.5rem;
		}

		.horizontal-arrow {
			width: 1.25rem;
			height: 0.0625rem;
			border: solid 0.0625rem #b6cbdc;
		}

		.dash {
			font-family: Arial;
			letter-spacing: -0.125rem;
			color: #b6cbdc;

			span {
				letter-spacing: 0.125rem; margin: 0 0.3125rem;
			}
		}
		.content-margin {
			margin-left: 2rem;
			margin-right: 2rem;
			margin-top: 1rem;
		}

		.logo-sub {
			font-family: 'MuseoSlab';
			font-size: 0.6875rem;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: -0.0006rem;
			text-align: center;
			color: #263b55;
		}

		.content-text {
			font-family: 'MuseoSlab';
			font-size: 0.9375rem;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: -0.0006rem;
			text-align: center;
			color: #263b55;
		}
	}
</style>
