import store from '@/store';
import { isSliceExtensionInstalled, isChrome } from '../utils/util';

const IF_NOT_AUTHENTICATED = (to, from, next) => {
	if (!store.getters['user/isAuth']) {
		return next();
	}

	next('/');
};

const IF_AUTHENTICATED = async (to, from, next) => {
	if (store.getters['user/isAuth']) {
		return next();
	}
	next('/');
};

const SHOULD_SEE_INTERSTITIAL_PAGE = async (to, from, next) => {
	if (store.getters['user/isAuth']) {
		const usingChrome = await isChrome();
		const extensionInstalled = await isSliceExtensionInstalled();
		if (!store.getters['user/getDisplayInterstitialPage'] || extensionInstalled || !usingChrome) {
			return next('/signup');
		}
		return next();
	}
	next('/signup');
};

const DISPLAY_FICTION_CHOICE_PAGE = (to, from, next) => {
	const userIsAuth = store.getters['user/isAuth'];
	if (userIsAuth) {
		const account = store.getters['user/account'];
		const tutorialShown = account.meta?.tutorial.createdSampleProject;
		if (!tutorialShown) {
			return next();
		}

		// Handle if user goes straight to this page
		if (from.path === '/') {
			return next('/projects');
		}

		return next(false);
	}

	return next('/signup');
};

export const container = 'common';

export const routes = [{
		path: '/login',
		redirect: '/signup',
	},{
	component: () => import(/* webpackChunkName: "auth" */ './LoginPage.vue'),
	path: '/signin',
	redirect: '/signup'
}, {
	component: () => import(/* webpackChunkName: "auth" */ './SignupPage.vue'),
	path: '/signup',
	name: 'signup',
	beforeEnter: IF_NOT_AUTHENTICATED,
	meta: {
		title: 'Signup',
		appClass: 'common no-container-padding'
	}
},
// {
// 	component: () => import(/* webpackChunkName: "auth" */ './ForgotPasswordPage.vue'),
// 	path: '/forgot-password',
// 	name: 'forgot_password',
// 	beforeEnter: IF_NOT_AUTHENTICATED,
// 	meta: {
// 		title: 'Forgot Password',
// 		appClass: 'common'
// 	}
// }, {
// 	component: () => import(/* webpackChunkName: "auth" */ './ResetPasswordPage.vue'),
// 	path: '/reset-password',
// 	name: 'reset_password',
// 	beforeEnter: IF_NOT_AUTHENTICATED,
// 	meta: {
// 		title: 'Reset Password',
// 		appClass: 'common'
// 	}
// },
{
	component: () => import(/* webpackChunkName: "auth" */ './ChromeWelcome.vue'),
	path: '/welcome',
	name: 'welcome',
	beforeEnter: SHOULD_SEE_INTERSTITIAL_PAGE,
	meta: {
		title: 'Welcome',
		appClass: 'common no-container-padding'
	}
}, {
	component: () => import(/* webpackChunkName: "auth" */ './ExtensionInstalled.vue'),
	path: '/extension-installed',
	name: 'extension-installed',
	meta: {
		title: 'Extension installed',
		appClass: 'common no-container-padding'
	},
}, {
	component: () => import(/* webpackChunkName: "auth" */ './FictionChoice.vue'),
	path: '/hello',
	name: 'hello',
	beforeEnter: DISPLAY_FICTION_CHOICE_PAGE,
	meta: {
		title: 'Choice',
		appClass: 'common no-container-padding'
	}
}];
