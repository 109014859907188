import store from '@/store';

const getUserInfo = () => store.getters['user/account'];

export function identify() {
	// const user = getUserInfo();
	// window.helpHero.identify(user.id, {
	// 	name: user.name,
	// 	email: user.email,
	// 	roles: user.roles,
	// });
}

export function trackEvent(eventName) {
	// window.helpHero.update({
	// 	event: eventName
	// });
}

export function updateUserProps(userProps) {
	// window.helpHero.update(userProps);
}

export function startTour(id, options) {
	// window.helpHero.startTour(id, {...options});
}
