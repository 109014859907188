export function account(state) {
	return state.account;
}

export function isAuth(state) {
	return !!state.auth;
}

export function roles(state) {
	return state.account.roles || [];
}

export function isAdmin(state) {
	const roles = state.account.roles || [];
	return roles.indexOf('ROLE_ADMIN') > -1;
}

export function getDisplayInterstitialPage(state) {
	return state.displayInterstitialPage;
}

export function accountLimits(state) {
	return state.account.limits;
}

export function authToken(state) {
	return state.token;
}

export function displayFictionChoicePage(state) {
	return state.displayFictionChoicePage;
}
