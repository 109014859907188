import _random from "lodash/random";

export default {
	methods: {
		getRandomWriterImage() {
			const imagePaths = [
				'gradient-female.svg',
				'gradient-male.svg',
				'gradient-neutral.svg',
				'indigo-female.svg',
				'indigo-male.svg',
				'indigo-neutral.svg',
				'royale-blue-female.svg',
				'royale-blue-male.svg',
				'royale-blue-female.svg',
				'turquoise-female.svg',
				'turquoise-male.svg',
				'turquoise-neutral.svg',
			];
			return require( '../assets/writer-icons/' + imagePaths[_random(0, (imagePaths.length - 1))]);
		},
		applyMirrorEffect() {
			return Date.now() % 2 === 0;
		}
	}
};
