import defaultState from './state';

export function SET_TOKEN(state, token) {
	state.token = token;
	state.auth = !!token;

	document.cookie = `sid=${token}`;
}

export function SET_ACCOUNT(state, account) {
	state.account = account;
}

export function SET_APIKEY(state, apikey) {
	state.apikey = apikey;
}

export function LOGOUT(state) {
	const def = defaultState();

	Object.keys(def).forEach(key => {
		if (key === 'displayInterstitialPage' || key === 'displayFictionChoicePage') {
			return;
		}
		state[key] = def[key];
	});

	document.cookie = '';
	sessionStorage.removeItem('Outseta.nocode.accessToken');
}

export function SET_DISPLAY_INTERSTITIAL_PAGE(state, bool) {
	state.displayInterstitialPage = bool;
}

export function UPDATE_ACCOUNT_LIMITS(state, limits) {
	state.account.limits = limits;
}

export function SET_DISPLAY_FICTION_CHOICE_PAGE(state, bool) {
	state.displayFictionChoicePage = bool;
}
